import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { useRecordContext } from 'react-admin';

const useStyles = makeStyles({
  firstLastNameField: {
    marginRight: '10px',
    marginTop: '5px',
    marginBottom: '5px',
  },
});

const FirstLastNameField = (props: any) => {
  const styles = useStyles();
  const record = useRecordContext();
  return <Chip size="small" label={`${record.first_name} ${record.last_name}`} className={styles.firstLastNameField} />;
};

export default FirstLastNameField;
