import {
  Create,
  maxValue,
  minValue,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { validateUrl } from '../../compositions';
import timezones, { currentTimezone } from '../../resources/timezones';
import TooltipInputField from '../TooltipInputField';
import { OpenHoursField } from '../FromTo';
import { defaultWeekdays } from '../../resources';

export default function CompanyCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} sx={{width: "250px"}}/>
        <TextInput source="webhook_url" validate={[validateUrl]} sx={{width: "250px"}}/>
        <SelectInput source="timezone" choices={timezones} defaultValue={currentTimezone} label="Time Zone" sx={{width: "250px"}}/>
        <TooltipInputField
          source="booking_day_limit"
          color="grey"
          value="Determine amount of days for further scheduling"
          validate={[minValue(1), maxValue(365), required()]}
          defaultValue={30}
          inputType="number"
        />
        <OpenHoursField source="open_time" defaultValues={defaultWeekdays}/>
      </SimpleForm>
    </Create>
  );
}
