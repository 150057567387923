import { LatLngLiteral, LatLngTuple, LeafletMouseEvent } from 'leaflet';
import { NumberInput } from 'react-admin';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { MapContainer, Marker, useMapEvents } from 'react-leaflet';
import { makeStyles } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import { getAddressFromLocation } from '../../compositions/http/events';
import SearchField from './SearchField';
import getLatLng from '../../compositions/getLatLng';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const useStyles = makeStyles({
  latLngInput: {
    display: 'none',
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const DEFAULT_CENTER_POINT: LatLngTuple = [51.505, -0.09];
const DEFAULT_ZOOM = 13;

const MapInput = () => {
  const record = useRecordContext();
  const initialPosition = record ? { lat: record.lat, lng: record.lng } : null;
  const initialAddress = record ? record.address : null;
  const [position, setPosition] = useState<LatLngLiteral | null>(initialPosition);
  const [address, setAddress] = useState<string | null>(initialAddress);
  const { setValue } = useForm();
  const styles = useStyles();

  const LocationMarker = () => {
    useMapEvents({
      click: (e: LeafletMouseEvent) => {
        setPosition({ lat: e.latlng.lat, lng: e.latlng.lng });
        const { lat, lng } = getLatLng(e.latlng.lat, e.latlng.lng);
        setValue('lat', lat);
        setValue('lng', lng);
        getAddressFromLocation({ lat, lng })
          .then(({ data }) => {
            setAddress(data.address);
          })
          .catch(() => {
            setAddress('Address not found');
          });
      },
      //Geosearch package is not 100% typescript
      //@ts-ignore
      'geosearch/showlocation': ({ location }) => {
        const { lat, lng } = getLatLng(location.y, location.x);
        setValue('lat', lat);
        setValue('lng', lng);
        setPosition({
          lat: location.y,
          lng: location.x,
        });
        getAddressFromLocation({ lat, lng })
          .then(({ data }) => {
            setAddress(data.address);
          })
          .catch(() => {
            setAddress('Address not found');
          });
      },
    });

    return position === null ? null : <Marker position={position || [1, 1]} />;
  };

  return (
    <div className={styles.mapContainer}>
      <p>{address}</p>
      <MapContainer center={position || DEFAULT_CENTER_POINT} zoom={DEFAULT_ZOOM} fullWidth>
        <SearchField />
        <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_MAPS_API as string} type={'hybrid'} />
        <LocationMarker />
      </MapContainer>
      <NumberInput source="lat" value={position?.lat || null} className={styles.latLngInput} />
      <NumberInput source="lng" value={position?.lng || null} className={styles.latLngInput} />
    </div>
  );
};

export default MapInput;
