import { Edit, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from "react-admin";

export const editEventGroups = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" sx={{width: "250px"}}/>
            <TextInput source="name" sx={{width: "250px"}}/>
            <ReferenceArrayInput source="event_types" reference="calendars/event-types">
                <SelectArrayInput optionText="name" sx={{width: "250px"}}/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit >
);