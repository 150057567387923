import { useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  useDataProvider,
  required,
  SelectInput,
} from 'react-admin';
import TooltipField from '../TooltipInputField';
import { OpenHoursField } from '../FromTo';
import { timezones, currentTimezone } from '../../resources';
import AddStripeAccount from '../stripePayments/AddStripeAccount';
import DiplayStripeAccount from '../stripePayments/DiplayStripeAccount';

export default function CompanyEdit(props: any) {
  const dataProvider = useDataProvider();
  const [title, setTitle] = useState(null);
  const [paymentDataId, setPaymentDataId] = useState(null)
  const { id } = props;

  useEffect(() => {
    dataProvider.getOne('companies', { id }).then(({ data }) => {
      const { name, payment_data } = data;
      setTitle(name);
      setPaymentDataId(payment_data);
    });
  }, [dataProvider, id]);


  return (
    <Edit {...props} mutationMode="pessimistic" title={title || 'Loading...'}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput source="name" validate={[required()]} />
        <TooltipField
          source="webhook_url"
          color="grey"
          value="This is the callback URL, that SMRT:Sync will request upon any of your meetings edition or change. Ask your company's developer to provide you the Webhook URL"
        />
        <SelectInput source="timezone" choices={timezones} defaultValue={currentTimezone} label="Time Zone" />
        <TooltipField source="slug" disabled color="grey" value="Slug is a suffix of your Company's name." />
        { paymentDataId ? 
          <DiplayStripeAccount /> :
          <AddStripeAccount {...props}></AddStripeAccount> }
        <OpenHoursField source="open_time" />
      </SimpleForm>
    </Edit>
  );
}
