import { calendarStyles, MeetingTooltip } from './CalendarStyles';
import Button from '@mui/material/Button';
import { ICalendarField } from '../../types';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import PeopleIcon from '@mui/icons-material/People';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const MeetingInfo = (props: Pick<ICalendarField, 'status' | 'start'>) => {
  const { status, start } = props;
  const useStyles = calendarStyles();
  const styles = useStyles();

  return (
    <div className={`${styles.meetingTime} ${styles.noPadding}`}>
      <div className={`${styles.badge} ${status === 1 ? styles.active : styles.canceled}`} />
      <p className={styles.start}>{start}</p>
    </div>
  );
};

const TooltipContent = (props: ICalendarField) => {
  const { status, start, uuid, url, host, name, location } = props;
  const useStyles = calendarStyles();
  const styles = useStyles();

  let key = '';
  try {
    const auth = localStorage.getItem('auth') || '';
    key = JSON.parse(auth).key;
  } catch {
    window.location.replace('/');
  }

  return (
    <div className={styles.meetingDetails}>
      <h2>{name}</h2>
      { status === 2 && <h3 className={styles.status}>CANCELED</h3>}
      
      <MeetingInfo status={status} start={start} />
      <p className={styles.host}>Hosted by {host}</p>

      { url !== null && 
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        href={url}
        target="_blank"
        rel="noreferrer"
        disabled={status === 2}
      >
        Go to google meeting
      </Button>
      }

      { location !== 4 &&
        <Button
          variant="contained"
          color="primary"
          href={`${process.env.REACT_APP_FRONTEND_URL}/edit/${uuid}/?key=${key}`}
          disabled={status === 2}
        >
        Edit
      </Button>
    }
    </div>
  );
};

export const Meeting = (props: ICalendarField) => {
  const { status, name, location } = props;
  const useStyles = calendarStyles();
  const styles = useStyles();

  return (
    <MeetingTooltip title={<TooltipContent {...props} />} placement="left-start">
      {/* // can't render it from the MeetingInfo component due to forwardRef problems that I can' solve */}
      <div className={styles.meetingInfo}>
        <div className={styles.meetingTime}>
          <div className={`${styles.badge} ${status === 1 ? styles.active : styles.canceled}`} />
          {/* <p className={styles.start}>{start}</p> */}
          { location === 3  ? <PeopleIcon /> : location === 4 ? <CalendarTodayIcon/> : <VideoCallIcon/> }
          <p className={styles.name}>{name}</p>
        </div>
      </div>
    </MeetingTooltip>
  );
};
