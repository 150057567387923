import { HttpResponse } from '../../types';
import constructUrl from '../constructUrl';
import { cancelSubscriptionUrl, createCheckoutSessionUrl, getStripePublicKeyUrl, subscriptionInfoUrl } from './endpoints';
import http from './http';

export const createCheckoutSession = (user_to_be_paid: any): Promise<HttpResponse<any>> => {
  const url = constructUrl(createCheckoutSessionUrl, { "user_to_be_paid": user_to_be_paid })
  return http({
    url: url,
    handleError: true,
    putToken: true,
  })
};

export const cancelSubscription = (user_to_be_unsubscribed: any): Promise<HttpResponse<any>> =>
  http({
    url: constructUrl(cancelSubscriptionUrl, { "user_to_be_unsubscribed": user_to_be_unsubscribed }),
    handleError: true,
    putToken: true,
  });

export const subscriptionInfo = (user_id: any): Promise<HttpResponse<any>> =>
  http({
    url: constructUrl(subscriptionInfoUrl, { "user": user_id }),
    handleError: true,
    putToken: true,
  });

export const getPublicKey = (): Promise<HttpResponse<any>> =>
  http({
    url: getStripePublicKeyUrl,
    handleError: true,
    putToken: true,
  });