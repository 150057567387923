import { TObject } from '../../types';
import { ErrorResponse, HttpResponse, DataInterface } from '../../types/http';
import { getAuthHeader } from '../jwt/jwtHttpClient';

interface QueryProps {
  method?: 'get' | 'post' | 'delete' | 'put' | 'patch' | 'options';
  url: string;
  payload?: unknown;
  handleError?: boolean;
  putToken?: boolean;
  options?: TObject;
}

const NEED_LOGOUT_STATUSES = [401, 403];

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const getHeaders = (putToken: boolean): any => {
  const headers = {
    ...DEFAULT_HEADERS,
  };

  if (!putToken) {
    return headers;
  }

  // let key = '';
  // try {
  //   const auth = localStorage.getItem('auth') || '';
  //   key = JSON.parse(auth).key;
  // } catch {
  //   window.location.replace('/');
  // }
  return {
    ...headers,
    ...getAuthHeader()
  };
};

export const getResponseJson = <T>(response: Response): Promise<HttpResponse<T>> => {
  const { status, ok } = response;
  if (response.headers.get('Content-Length') === '0') {
    return Promise.resolve({
      data: {} as T,
      status,
      ok,
    });
  }
  const responseData = response.json();
  return responseData.then((data: T) => {
    return {
      data,
      status,
      ok,
    };
  });
};

export const validateResponse = <T = unknown>(data: HttpResponse<T>): HttpResponse<T> => {
  const { ok } = data;
  if (!ok) {
    throw new Error(`--response-- ${JSON.stringify(data)}`);
  }
  return data;
};

export const catchError = (e: string): { message: string } => {
  if (String(e).indexOf('Error: --response-- ') === 0) {
    const stringForParse = String(e).replace('Error: --response-- ', '');
    const { status, data } = JSON.parse(stringForParse) as { status: number; data: unknown };
    if (NEED_LOGOUT_STATUSES.includes(status)) {
      window.location.replace('/');
    }
    throw new Error(JSON.stringify(data));
  }
  throw new Error(e);
};

export const getError = (messageData: string): ErrorResponse => {
  const error = {
    status: 0,
    message: 'Unknown error!',
  };

  try {
    const stringForParse = String(messageData).replace('Error: --response-- ', '');
    // https://www.django-rest-framework.org/api-guide/exceptions/
    const { status, data } = JSON.parse(stringForParse) as HttpResponse<DataInterface>;
    const errorResult = { ...error, status };

    const entries = Object.entries(data);

    if (entries.length) {
      const message = entries
        .map(([key, value]: [string, string | string[]]) => {
          const [text] = Array.isArray(value) ? value : [value];
          return `${key}: ${text}`;
        })
        .join('; ');

      return {
        ...errorResult,
        message,
      };
    }

    return errorResult;
  } catch (e) {
    return error;
  }
};

export default function http<T = unknown>({
  method = 'get',
  url,
  payload,
  handleError = true,
  putToken = true,
  options = {},
}: QueryProps): Promise<HttpResponse<T>> {
  const controller = new AbortController();
  const { signal } = controller;
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  let body;

  try {
    body = JSON.stringify(payload);
  } catch (e) {
    return Promise.reject(e);
  }

  const request = fetch(`${BASE_URL}${url}`, {
    method,
    headers: getHeaders(putToken),
    body,
    signal,
    ...options,
  })
    .then((response) => getResponseJson<T>(response))
    .then((data) => validateResponse<T>(data));

  if (handleError) {
    request.catch(catchError);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Unreachable code error
  request.cancel = () => controller.abort();

  return request;
}
