import { useNotify } from 'react-admin';
import { removeGoogleCalendar } from '../../compositions/http';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { UserCalendar } from '../../types';

interface UserCalendarFieldProps {
  id: number;
  text: string;
  calendars: {
    calendars: UserCalendar[];
    setCalendars: React.Dispatch<React.SetStateAction<UserCalendar[]>>;
  };
}

const UserCalendarField = ({ id, text, calendars: { calendars, setCalendars } }: UserCalendarFieldProps) => {
  const notify = useNotify();
  const handleRemove = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm('Are you sure?')) {
      return;
    }
    removeGoogleCalendar(text)
      .then(() => {
        setCalendars([...calendars.filter((c) => c.id !== id)]);
        notify(`Removed ${text} calendar`);
      })
      .catch(() => {
        notify("Couldn't remove Google Calendar");
      });
  };
  return (
    <Card variant="outlined" style={{ display: 'flex', minWidth: "256px", maxWidth: 'fit-content' }}>
      <CardContent>
        <Typography color="secondary" component="div" gutterBottom>
          Google Calendar
        </Typography>
        <Typography variant="inherit" component="div">
          {text}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={handleRemove} size="large">
          <RemoveCircleOutlineIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export default UserCalendarField;
