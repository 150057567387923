import { useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  SelectArrayInput,
  SelectInput,
  PasswordInput,
  useNotify,
  FormDataConsumer,
  useRecordContext,
  useGetIdentity,
  usePermissions
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { ITeam } from '../../types';
import { teamsGet } from '../../compositions/http';
import GoogleCalendarSettings from '../GoogleCalendar/GoogleCalendarSettings';
import { SubscriptionInfoManager } from '../stripePayments/SubscriptionInfoManager';
// import { isUnparsedNode } from 'typescript';

const fieldStyles = () => {
  return makeStyles({
    field: {
      width: '256px',
    },
    fieldDisabled: {
      display: 'none !important',
    },
    form: {
      '& > *': {
        display: 'block',
        '& > *': {
          width: '256px',
        },
      },
    },
  });
};

const TeamsFetcher = (props: any) => {
  const notify = useNotify();
  const { setTeams } = props;
  const { company_id } = useRecordContext();
  const id = company_id[0];


  useEffect(() => {
    teamsGet(id)
      .then(({ data }) => {
        setTeams([...data.results.map((team: ITeam) => ({ id: team.id, name: team.name }))]);
      })
      .catch((error) => {
        notify(error.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return <></>;
};

export default function UserEdit(props: any) {
  const [teams, setTeams] = useState<ITeam[]>([{ id: 1, name: '1' }]);
  const useStyles = fieldStyles();
  const styles = useStyles();
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();

  return (
    <Edit {...props} title={`Host #${props.id}`}>
      <SimpleForm>
        <TextInput disabled source="id" sx={{width: "250px"}}/>
        <TextInput disabled source="last_login_date" sx={{width: "250px"}}/>
        <TextInput source="first_name" validate={[required()]} sx={{width: "250px"}}/>
        <TextInput source="last_name" validate={[required()]} sx={{width: '250px'}}/>
        <TextInput type="email" source="email" validate={[email(), required()]} sx={{width: '250px'}}/>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) => {
            return (
              formData && (
                <div className={styles.form}>
                  {formData.role === 1 ? (
                    <SelectInput
                      choices={[
                        { id: 1, name: 'Super Admin' },
                        { id: 2, name: 'User' },
                        { id: 3, name: 'Company Admin' },
                      ]}
                      source="role"
                      required
                      disabled
                      className={styles.field}
                    />
                  ) : (
                    <SelectInput
                      choices={[
                        { id: 2, name: 'User' },
                        { id: 3, name: 'Company Admin' },
                      ]}
                      source="role"
                      required
                      className={styles.field}
                    />
                  )}
                  <SelectArrayInput source="teams" choices={teams} validate={[required()]} />
                  {formData.role !== 3 ? (
                    <PasswordInput source="password" disabled className={styles.fieldDisabled} />
                  ) : (
                    <PasswordInput source="password" className={styles.field} />
                  )}
                </div>
              )
            );
          }}
        </FormDataConsumer>
        {
          permissions?.membership_type === 1 ?
            <SubscriptionInfoManager/> : null
        }
        <br />
        {identity?.id === parseInt(props.id) ?
          <>
            <GoogleCalendarSettings />
          </> : null}

        <TeamsFetcher setTeams={setTeams} />
      </SimpleForm>
    </Edit>
  );
}
