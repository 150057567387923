import { baseUrl } from "../http/apis"

const inMemoryJWTManager = () => {
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = baseUrl + '/token/refresh/'
    let inMemoryJWT = null;
    let refreshTimeOutId = null;
    let isRefreshing = null;

    // This listener allows to disconnect another session of react-admin started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            console.log("event.key === logoutEventName")
            inMemoryJWT = null;
        }
    });

    // This countdown feature is used to renew the JWT in a way that is transparent to the user.
    // before it's no longer valid
    const refreshToken = (delay) => {
        console.log("delay: " + delay)
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    const getRefreshedToken = () => {
        console.log("getRefreshedToken")
        const request = new Request(refreshEndpoint, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            credentials: 'include',
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    return { token: null };
                }
                return response.json();
            })
            .then(({ access, delay }) => {
                if (access) {
                    setToken(access, delay);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const getToken = () => inMemoryJWT;

    const setToken = (token, delay) => {
        console.log("starting refresh token")
        inMemoryJWT = token;
        refreshToken(delay);
        return true;
    };

    const ereaseToken = () => {
        console.log("erasing token")
        inMemoryJWT = null;
        abordRefreshToken();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    const setLogoutEventName = name => logoutEventName = name;

    return {
        ereaseToken,
        getToken,
        setLogoutEventName,
        waitForTokenRefresh,
        getRefreshedToken,
        setToken,
    }
};

export default inMemoryJWTManager();
