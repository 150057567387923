import { IResultsData, TObject } from '../../types';
import { HttpResponse } from '../../types/http';
import { meetingsDeleteUrl, getReassignHostsUrl, meetingsReassignUrl, MEETINGS_URL, GOOGLE_CALENDAR_MEETINGS_URL } from './endpoints';
import http from './http';
import { constructUrl } from '../../compositions';

interface ReassignHostsData {
  available_hosts: {
    id: number;
    full_name: string;
  }[];
}

export const meetingDelete = (uuid: string) =>
  http({
    method: 'delete',
    url: meetingsDeleteUrl(uuid),
    putToken: true,
    handleError: true,
  });

export const meetingReassign = (uuid: string, payload: { host_id: number }) =>
  http({
    method: 'post',
    url: meetingsReassignUrl(uuid),
    putToken: true,
    handleError: true,
    payload,
  });

export const getReassignHosts = (uuid: string): Promise<HttpResponse<ReassignHostsData>> =>
  http({
    url: getReassignHostsUrl(uuid),
    putToken: true,
    handleError: false,
  });

export const getMeetings = (filters: TObject): Promise<HttpResponse<IResultsData>> =>
  http({
    url: constructUrl(MEETINGS_URL, filters),
    putToken: true,
    handleError: false,
  });

  export const getGoogleCalendarMeetings = (filters: TObject): Promise<HttpResponse<IResultsData>> =>
  http({
    url: constructUrl(GOOGLE_CALENDAR_MEETINGS_URL, filters),
    putToken: true,
    handleError: false,
  });