import { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  email,
  SelectArrayInput,
  SelectInput,
  PasswordInput,
  useNotify,
  usePermissions,
  FormDataConsumer,
} from 'react-admin';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { teamsGet, userGetCompanies } from '../../compositions/http';
import { ITeam } from '../../types/team';
import { ICompany } from '../../compositions/http/company';
import { IChoice } from '../../types';

const passwordFieldStyles = () => {
  return makeStyles({
    password: {
      width: '256px',
    },
    passwordDisabled: {
      display: 'none',
    },
  });
};

export default function UserCreate(props: any) {
  const [companies, setCompanies] = useState<IChoice[]>([]);
  const [endpoint, setEndpoint] = useState<string>('');
  const [teams, setTeams] = useState<IChoice[]>([]);
  const notify = useNotify();
  const useStyles = passwordFieldStyles();
  const styles = useStyles();

  useEffect(() => {
    userGetCompanies()
      .then(({ data }) => {
        setCompanies([...data.results.map((company: ICompany) => ({ id: company.id, name: company.name }))]);
        if (data.results.length === 1) {
          setEndpoint(`${data.results[0].id}`);
        }
      })
      .catch((error) => {
        notify(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    teamsGet(endpoint)
      .then(({ data }) => {
        setTeams([...data.results.map((team: ITeam) => ({ id: team.id, name: team.name }))]);
      })
      .catch((error) => {
        notify(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, endpoint]);

  const handleOnCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndpoint(`${event.target.value}`);
  };

  const CustomSelectArrayInputField = (props: any) => {
    const { source, choices, validate, teams, ...rest } = props;
    const { getValues, setValue } = useForm();

    useEffect(() => {
      const values = getValues();
      if (values.teams && values.companies) {
        setValue('teams', values.teams.filter((team: any) => teams.map((teamChoice: any) => teamChoice.id).includes(team)));
      }
    }, [getValues, setValue, teams, props]);

    return <SelectArrayInput {...rest} choices={choices} source={source} validate={validate} />;
  };

  const CompanyInput = (props: any) => {
    const { permissions } = usePermissions();

    return permissions?.role === 3 && companies.length ? (
      <TextInput source='companies' defaultValue={companies[0].name} disabled sx={{width: "250px"}}/>
    ) : (
      <SelectInput
        {...props}
        choices={companies}
        source="companies"
        onChange={handleOnCompanyChange}
        validate={[required()]}
        sx={{width: "250px"}}
      />
    );
  };

  return (
    <Create {...props} title="Create host">
      <SimpleForm>
        <TextInput source="first_name" validate={[required()]} sx={{width: "250px"}}/>
        <TextInput source="last_name" validate={[required()]} sx={{width: "250px"}}/>
        <TextInput type="email" source="email" validate={[email(), required()]} sx={{width: "250px"}}/>
        <SelectInput
          choices={[
            { id: 2, name: 'User' },
            { id: 3, name: 'Company Admin' },
          ]}
          source="role"
          defaultValue={2}
          required
          sx={{width: "250px"}}
        />
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData }) =>
            formData.role === 2 ? (
              <PasswordInput source="password" disabled className={styles.passwordDisabled} />
            ) : formData.role ? (
              <PasswordInput source="password" validate={[required()]} className={styles.password} />
            ) : (
              <></>
            )
          }
        </FormDataConsumer>
        <CompanyInput />
        <CustomSelectArrayInputField source="teams" choices={teams} validate={[required()]} teams={teams} sx={{width: "250px"}}/>
      </SimpleForm>
    </Create>
  );
}
