import { LatLngLiteral } from 'leaflet';
import { IAddress, OpenTime, IEventType } from '../../types';
import { HttpResponse } from '../../types/http';
import {
  EVENTS_GET_ADDRESS_URL,
  eventTypeEdit,
  EVENT_TYPE_CREATE,
  privateEventDetailsEdit,
  PRIVATE_EVENT_TYPE_DETAILS,
} from './endpoints';
import http from './http';

export const getAddressFromLocation = (location: LatLngLiteral): Promise<HttpResponse<IAddress>> =>
  http({
    method: 'post',
    url: EVENTS_GET_ADDRESS_URL,
    putToken: true,
    handleError: true,
    payload: { ...location },
  });

export const postPrivateEventTypeDetails = (payload: {
  private_time_availability: OpenTime[];
  private_time_zone: string;
}) =>
  http({
    method: 'post',
    url: PRIVATE_EVENT_TYPE_DETAILS,
    putToken: true,
    handleError: true,
    payload,
  });

export const postPrivateEventType = (payload: any) =>
  http({
    method: 'post',
    url: EVENT_TYPE_CREATE,
    putToken: true,
    handleError: true,
    payload,
  });

export const putPrivateEventTypeDetails = (
  payload: {
    private_time_availability: OpenTime[];
    private_time_zone: string;
  },
  id: number,
) =>
  http({
    method: 'put',
    url: privateEventDetailsEdit(id),
    putToken: true,
    handleError: true,
    payload,
  });

export const putPrivateEventType = (payload: any, id: number) =>
  http({
    method: 'put',
    url: eventTypeEdit(id),
    putToken: true,
    handleError: true,
    payload,
  });

export const getPrivateEventTypeDetails = (id: number) =>
  http({
    url: privateEventDetailsEdit(id),
    putToken: true,
    handleError: true,
  });

export const getEventData = (id: number): Promise<HttpResponse<IEventType>> =>
  http({
    url: `/calendars/event-types/${id}`,
    handleError: true,
    putToken: false,
  });

export const getEvents = (page: number, page_size: number): Promise<HttpResponse<any>> =>
  http({
    url: `/calendars/event-types/?page=${page}&page_size=${page_size}`,
    handleError: true,
    putToken: true,
  });
