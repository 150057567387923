import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useInput } from 'react-admin';
import { useState } from 'react';
import dayjs from 'dayjs';

const FromToInput = (props: any) => {
  const { label, defaultValue, ...rest } = props;
  const { field } = useInput({ ...rest });
  const [fromToHours, setFromToHours] = useState(defaultValue);

  const parseHoursToDayjs = (fromToHours:any) => {
    if (!fromToHours || fromToHours === null){
      return null;
    } 
    const [hours, minutes] = fromToHours.split(':');
    return dayjs().hour(parseInt(hours)).minute(parseInt(minutes));
  };

  const handleHoursChange = (newHours:any) => {
    const strHours = newHours.format('HH:mm');
    setFromToHours(strHours);
    field.onChange(strHours);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
          {...field}
          label={label}
          value={parseHoursToDayjs(fromToHours)}
          onChange={(newValue) => handleHoursChange(newValue)}
          views={['hours', 'minutes']}
          ampm={false}
          sx={{width: "150px"}}
      />
    </LocalizationProvider>
  )
}

export default FromToInput;