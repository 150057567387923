import { Alert, AlertTitle } from "@mui/material"

export const PaymentErrorInfo = () => {
    return (
        <Alert severity="error">
            <AlertTitle>Payment Error</AlertTitle>
            An error during payment occured. <br />
            <b> No fee was charged. </b>
        </Alert>
    )
}