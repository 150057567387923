import Button from '@mui/material/Button';
import authProvider from '../../compositions/jwt/jwtAuthProvider';

export default function AddStripeAccount(props: any) {

  const key = authProvider.getToken()

  return (
    <div>
      <Button
        href={`${process.env.REACT_APP_BACKEND_URL}/payments/add-stripe-account/?key=${key}&company_id=${props.id}`}
        color="primary"
        variant="contained"
        size="small"
      >
        Add Stripe Account
      </Button>
    </div>
  )
}

