export const EVENTS_GET_ADDRESS_URL = '/calendars/get_address/';
export const MEETINGS_URL = '/calendars/meetings/';
export const GOOGLE_CALENDAR_MEETINGS_URL = '/calendars/google-calendar-meetings/';
export const COMPANIES_URL = '/companies';
export const PRIVATE_EVENT_TYPE_DETAILS = '/calendars/private-event-details/';
export const EVENT_TYPE_CREATE = '/calendars/event-types/';
export const CHANGELOG_URL = '/calendars/changelog';
export const CALENDAR_URL = '/calendar/overview'

export const meetingsDeleteUrl = (uuid: string) => `${MEETINGS_URL}${uuid}`;
export const meetingsReassignUrl = (uuid: string) => `${MEETINGS_URL}${uuid}/reassign_meeting/`;
export const getReassignHostsUrl = (uuid: string) => `${MEETINGS_URL}${uuid}/hosts_to_reassign_meeting/`;

export const userReassignAllUrl = (id: number) => `/users/${id}/reassign_all_meetings/`;
export const userGetByCompanyIdUrl = (id: number) => `/users/?company_id=${id}`;
export const removeGoogleCalendarUrl = (email: string) => `/users/remove-google-calendar/?calendar_email=${email}`;

export const teamsGetUrl = (companyId: string) => (companyId ? `/teams/?company_id=${companyId}` : '/teams');

export const eventTypeEdit = (id: number) => `/calendars/event-types/${id}/`;
export const privateEventDetailsEdit = (id: number) => `/calendars/private-event-details/${id}/`;

export const subscriptions_url = "payments/subscriptions"
export const createCheckoutSessionUrl = "/payments/subscription/create-checkout-session/"
export const cancelSubscriptionUrl = "/payments/subscription/cancel/"
export const subscriptionInfoUrl = "/payments/subscription/info/"
export const getStripePublicKeyUrl = "/payments/stripe-public-key/"

export const TOKEN_URL = "/token/";
export const REFRESH_TOKEN_URL = "/token/refresh/"
