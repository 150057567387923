import { List, Datagrid, TextField, RichTextField, DateField } from 'react-admin';

export default function ChangelogList(props: any) {

  return (
    <List {...props} >
      <Datagrid rowClick="edit">
        <TextField source="id"/>
        <TextField source="version"/>
        <RichTextField source="content"/>
        <DateField source="created_at"/>
        <DateField source="released_at"/>
      </Datagrid>
    </List>
  );
}


