import { 
  List, 
  Datagrid, 
  TextField, 
  RichTextField, 
  ReferenceField, 
  ArrayField, 
  Filter, 
  TextInput, 
  usePermissions, 
  useListContext,
  useListController,
} from 'react-admin';
import ListContainer from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '../Link';
import Button from '@mui/material/Button';
import { containerStyles } from '../../styles';

const ArrayItemField = () => {
  const { data } = useListContext();

  return (
    <ListContainer>
      {data.map((id) => (
        <ListItem key={id}>
          <ListItemText primary={id} />
        </ListItem>
      ))}
    </ListContainer>
  );
};

const EventTypesFilter = (props: any) => {
  const styles = containerStyles();
  const { setFilters } = useListController();

  const clearFields = () => {
    setFilters({}, []);
  };

  return (
    <div className={styles.container}>
      <Filter {...props}>
        <TextInput source="slug" label="Slug" alwaysOn resettable />
        <TextInput source="team__slug" label="Team's slug" alwaysOn resettable />
      </Filter>
      {props.context === 'button' ? (
        ''
      ) : (
        <Button variant="outlined" color="primary" onClick={clearFields}>
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default function EventTypesList(props: any) {
  const { permissions } = usePermissions();

  return (
    <List {...props} filters={<EventTypesFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        {(permissions?.role === 1 || permissions?.role === 3) && (
          <ReferenceField label="Team" source="team" reference="teams" sortable={false}>
            <TextField source="name" />
          </ReferenceField>
        )}
        <TextField source="duration" sortable={false} />
        <RichTextField source="description" />
        <ArrayField source="questions" sortable={false}>
          <ArrayItemField />
        </ArrayField>
        <Link source="url" sortable={false} />
      </Datagrid>
    </List>
  );
}
