const defaultWeekdays = [
  { to: '17:00', from: '09:00', active: true, weekday: 'Monday' },
  { to: '17:00', from: '09:00', active: true, weekday: 'Tuesday' },
  { to: '17:00', from: '09:00', active: true, weekday: 'Wednesday' },
  { to: '17:00', from: '09:00', active: true, weekday: 'Thursday' },
  { to: '17:00', from: '09:00', active: true, weekday: 'Friday' },
  { to: null, from: null, active: false, weekday: 'Saturday' },
  { to: null, from: null, active: false, weekday: 'Sunday' },
];

export default defaultWeekdays;
