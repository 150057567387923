import { Stack } from "@mui/material";
import { useEffect, useState } from 'react';
import { BooleanInput, DateTimeInput, FormDataConsumer, NumberInput, required, SelectInput, useNotify, usePermissions } from "react-admin";
import { userGetCompanies } from '../../compositions/http';
import { currentTimezone } from '../../resources/timezones';
import { MapInput } from "../Map";
import { PremiumPlanPopup } from "../stripePayments/PlansDescriptions";
import { HooverInfo } from "./HooverInfo";

const locationChoices = [
    { id: 1, name: "Smrt:Sync" },
    { id: 3, name: "Address" },
];

const currencyChoices = [
    { id: 'pln', name: "PLN" },
    { id: 'gbp', name: "GBP" },
    { id: 'eur', name: "EUR" },
];

const recurringChoices = [
    { id: 1, name: "Doesn't repeat" },
    { id: 2, name: "Every week" },
    { id: 4, name: "Every two weeks" },
    { id: 3, name: "Every month" },
];

export const RecurrenceInput = (props: any) => {
    const [selectedRecurrance, setSelectedRecurrence] = useState(1)

    const handleRecurrenceChange = ({ target }: any) => {
        setSelectedRecurrence(target.value);
    }

    return (
        <>
            <SelectInput source="recurring" choices={recurringChoices} defaultValue={1} onChange={handleRecurrenceChange} sx={{ width: "250px" }} />
            {
                selectedRecurrance === 1 ? null : <NumberInput source="occurrences" style={{ marginLeft: '10px' }} />
            }
        </>
    )
}

export const LocationInput = (props: any) => {

    return (
        <>
            <SelectInput source="location" choices={locationChoices} sx={{ width: "250px" }} />
            <FormDataConsumer subscription={{ values: true }}>
                {
                    ({ formData }) =>
                        formData && formData.location === 3
                            ? <MapInput />
                            : <></>
                }
            </FormDataConsumer>
        </>
    )
}

export const TeamMeetingInput = (props: any) => {
    const { isFreeAccout } = props;
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="baseline">
            <BooleanInput readOnly={isFreeAccout} source="team_meeting" label="Internal team meeting" />
            <HooverInfo info="Find dates that suit all team members and invite them all to the meeting." />
            {isFreeAccout ? <PremiumPlanPopup /> : null}
        </Stack>
    )
}

export const PaidMeetingInput = (props: any) => {
    const notify = useNotify();
    const { isFreeAccout } = props;
    const [hasPaymentData, setHasPaymentData] = useState(false);
    const { permissions } = usePermissions();
    useEffect(() => {
        userGetCompanies()
            .then(({ data }) => {
                let company = data.results
                if (company[0].payment_data != null) {
                    setHasPaymentData(true)
                }
            })
            .catch((error) => {
                notify(error.message);
            });
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline">
                {permissions?.role !== 2 ? <BooleanInput disabled={isFreeAccout && !hasPaymentData} source="paid" label="Paid" /> : null}
                {permissions?.role !== 2 ? <HooverInfo info="Create paid meetings. Enabled only when payment is configured for given company." /> : null}
                {isFreeAccout ? <PremiumPlanPopup /> : null}

            </Stack>
            <FormDataConsumer subscription={{ values: true }}>
                {
                    ({ formData }) =>
                        formData && formData.paid === true
                            ?
                            <>
                                <Stack direction="row">
                                    <NumberInput label="Price" source="price" validate={[required()]} style={{ marginRight: '10px', width: '200px' }} />
                                    <SelectInput source="currency" choices={currencyChoices} validate={[required()]} />
                                </Stack>
                            </>
                            : null
                }
            </FormDataConsumer>
        </>
    )
}

export const GroupMeetingInput = (props: any) => {
    const { isFreeAccout } = props;

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="baseline">
                <BooleanInput disabled={isFreeAccout} source="group_event" label="Group Event" />
                <HooverInfo info="Create meetings for many users. Usefull when organising conferences, lectures etc." />
                {isFreeAccout ? <PremiumPlanPopup /> : null}
            </Stack>
            <FormDataConsumer subscription={{ values: true }}>
                {
                    ({ formData }) => formData && formData.group_event === true
                        ?
                        <>
                            <NumberInput label="number of participants" source="max_participants" validate={[required()]} sx={{ width: "250px" }} />
                            <br />
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <DateTimeInput label="Date" source="fixed_date" validate={[required()]} sx={{ width: "250px" }} />
                                <span>Timezone: &nbsp; <b>{currentTimezone}</b></span>
                            </Stack>
                        </>
                        : null
                }
            </FormDataConsumer>
        </>
    )
}