import { Layout, useNotify } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import CustomSidebar from './CustomSidebar';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { getGoogleCalendarMeetings } from '../compositions/http';
import { OnboardingStepper } from '../components/OnboardingStepper/OnboardingStepper';

const useStyles = makeStyles({
  content: {
    marginTop: '16px',
  },
});

const CustomLayout = (props: any) => {
  const notify = useNotify();
  const styles = useStyles();

  useEffect(() => {
    getGoogleCalendarMeetings({}).then((data) => {
      if (Object.keys(data.data).length === 0) {
        notify(`Please provide Access to Google Calendar`, { type: 'warning' });
      }
    }).catch(() => {
      notify(`Please provide Access to Google Calendar`, { type: 'warning' });
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout {...props} appBar={CustomAppBar} sidebar={CustomSidebar} className={styles.content} />
      <OnboardingStepper />
    </>);
};

export default CustomLayout;
