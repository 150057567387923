import { HttpResponse } from '../../types';
import { ITeamResult } from '../../types/team';
import { teamsGetUrl } from './endpoints';
import http from './http';

export const teamsGet = (id: string): Promise<HttpResponse<ITeamResult>> =>
  http({
    url: teamsGetUrl(id),
    handleError: true,
    putToken: true,
  });
