import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useInput } from 'react-admin';
import { useRecordContext } from 'react-admin';
import { useState } from 'react';
import dayjs from 'dayjs';

const DurationInput = (props: any) => {
  const record = useRecordContext();
  const { ...rest } = props;
  const { field } = useInput({ ...rest });
  const [duration, setDuration] = useState(record ? record.duration : "01:00:00");

  const parseDurationToDayjs = (duration:any) => {
    const [hours, minutes, seconds] = duration.split(':');
    return dayjs().hour(parseInt(hours)).minute(parseInt(minutes)).second(parseInt(seconds));
  };

  const handleDurationChange = (newDuration:any) => {
    const strDuration = newDuration.format('HH:mm:ss');
    setDuration(strDuration);
    field.onChange(strDuration);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        {...field}
        label="Duration"
        value={parseDurationToDayjs(duration)}
        onChange={(newValue) => handleDurationChange(newValue)}
        views={['hours', 'minutes', 'seconds']}
        ampm={false}
        slotProps={{
          layout: {
            sx: {
              ul: {
                '::-webkit-scrollbar': {
                  width: '2px',
                },
              },
            },
          }
        }}
      />
    </LocalizationProvider>
  )
}

export default DurationInput;