import { Login } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import LoginForm from './LoginForm';

export default function MyLoginPage(props: any) {
  const navigate = useNavigate();
  const search = new URLSearchParams(window.location.search);
  const key = search.get('auth');

  if (key) {
    localStorage.setItem('auth', JSON.stringify({ key }));
    navigate('/');
  }
  return (
    <div>
      <Login {...props} style={{ padding: '16px' }}>
        <LoginForm />
      </Login>
    </div>
  );
}
