import React, { useEffect, useState } from 'react';
import { SelectArrayInput } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { userGetByCompanyId } from '../../compositions/http';
import { IChoice, IUser } from '../../types';

const useStyles = makeStyles({
  selectUsersField: {
    minWidth: '256px',
  },
});

const UserSelectInput = (props: any) => {
  const styles = useStyles();
  const company = useWatch({name: 'company'});
  const [users, setUsers] = useState<IChoice[]>([]);

  useEffect(() => {
    if (company) {
      userGetByCompanyId(company).then(({ data }) => {
        setUsers([...data.results.map((u: IUser) => ({ id: u.id, name: u.email }))]);
      });
    } else {
      setUsers([]);
    }
  },[company]);

  return <SelectArrayInput source="users" choices={users} className={styles.selectUsersField} defaultValue={[]} sx={{width: "250px"}}/>;
};

export default UserSelectInput;
