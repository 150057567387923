import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material'; 
import { useRefresh } from 'react-admin';
import { cancelSubscription } from '../../compositions/http/subscriptions';

export default function UnsubscribeButton(props: any) {
    const { id } = props;
    const { dueDay } = props;

    const refresh = useRefresh();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleDelete = () => {
        cancelSubscription(id).then((result) => {
            refresh();
        })
        handleClose();
    };

    return (
        <div>
            <Button
                onClick={handleClick}
                size="small" >
                Unsubscribe
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Grid>
                        <Typography sx={{ p: 2 }}> Do You want to unsubscribe? <br/> 
                                                   Premium will be enabled due to {dueDay}
                        </Typography>
                    </Grid>
                    <Grid>
                        <div style={{ marginBottom: "10px" }}>
                            <Button style={{ marginRight: "10px" }} variant="contained" onClick={handleDelete}> Yes</Button>
                            <Button style={{ marginLeft: "10px" }} variant="outlined" onClick={handleClose}>No</Button>
                        </div>
                    </Grid>
                </Grid>
            </Popover>
        </div>
    );
}