import { AppBar, usePermissions } from 'react-admin';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';

import logo from '../assets/logo.png';
import { LinearProgress } from '@mui/material';
import { MembershipChoices } from '../types/membership_constants';
import { AppBarSubscribeButton } from '../components/stripePayments/SubscriptionInfoManager';
import ConnectGoogleCalendarButton from '../components/login/ConnectGoogleCalendarButton'


const useStyles = makeStyles({
  title: {
    flex: 1,
  },
  logo: {
    maxWidth: '160px',
  },
});

const CustomAppBar = (props: any) => {
  const styles = useStyles();
  const { permissions } = usePermissions();

  return (
    <AppBar {...props} color="default">
      <Toolbar>
        <img src={logo} alt="logo" className={styles.logo} />
      </Toolbar>
      <Typography variant="h6" color="inherit" className={styles.title} id="react-admin-title" />
      <ConnectGoogleCalendarButton/>
      {permissions ?
        <>
          <Typography variant='button'>
            <b>{MembershipChoices[permissions.membership_type as keyof typeof MembershipChoices]}</b> Plan &nbsp; &nbsp;
          </Typography>
          {
            permissions?.membership_type === 1 ?
              <AppBarSubscribeButton id={permissions.id} /> : null
          }
        </> : < LinearProgress />
      }
    </AppBar>
  );
};

export default CustomAppBar;