import { NumberInput, TextInput } from 'react-admin';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { IToolTip } from '../../types';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: 256,
  },
  input: {
    width: '100%',
  },
  tooltip: {
    position: 'absolute',
    top: 5,
    right: -28,
  },
});

export default function TooltipInput({ color = 'black', inputType = 'text', value, ...rest }: IToolTip) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      {inputType === 'number' ? <NumberInput {...rest} sx={{width: "250px"}} /> : <TextInput {...rest} sx={{width: "250px"}}/>}
      <div className={styles.tooltip}>
        <Tooltip title={value}>
          <InfoIcon htmlColor={color} />
        </Tooltip>
      </div>
    </div>
  );
}
