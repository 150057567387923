import { TextField, useRecordContext } from 'react-admin';
import { addZero } from '../../compositions';

export default function DateTimeField({ fieldKey }: any) {
  const record = useRecordContext();
  const { [fieldKey]: rawDate } = record;
  const date = new Date(rawDate);
  const formattedTime = `${date.getHours()}:${addZero(date.getMinutes())}`;
  const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  return <TextField emptyText={`${formattedDate} ${formattedTime}`} />;
}
