import { usePermissions } from 'react-admin';
import { List, Datagrid, TextField, EmailField } from 'react-admin';
import Button from '@mui/material/Button';
import authProvider from '../../compositions/jwt/jwtAuthProvider';

export default function ProfileList(props: any) {
  const key = authProvider.getToken()
  const { permissions } = usePermissions();

  return (
    <List {...props} title="Profile" bulkActionButtons={false} pagination={<></>}>
      <Datagrid rowClick="edit">
        <TextField source="first_name" />
        <TextField source="last_name" />
        <EmailField source="email" />
        <Button color="primary" variant="contained" size="small">
          Edit profile
        </Button>
        <Button
          href={`${process.env.REACT_APP_BACKEND_URL}/users/add-google-calendar/?token=${key}`}
          color="primary"
          variant="contained"
          size="small"
          disabled={permissions?.membership_type === 1}
        >
          Add calendar
        </Button>
      </Datagrid>
    </List>
  );
}
