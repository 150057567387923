import { Create, SimpleForm, TextInput, required, DateTimeInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export default function ChangelogCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="version" />
        <DateTimeInput source="created_at" validate={[required()]}/>
        <DateTimeInput source="released_at"/>
        <RichTextInput source="content" />
      </SimpleForm>
    </Create>
  );
}
