import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

const theme = merge({}, defaultTheme, {
  palette: {
    common: {
      black: 'rgba(40, 42, 58, 1)',
      white: '#fff',
    },
    background: {
      paper: '#fff',
      default: 'rgba(244, 244, 245, 1)',
    },
    primary: {
      light: 'rgba(151, 173, 255, 1)',
      main: 'rgba(46, 91, 254, 1)',
      dark: 'rgba(41, 82, 229, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(136, 153, 235, 1)',
      main: 'rgba(16, 51, 214, 1)',
      dark: 'rgba(14, 46, 193, 1)',
      contrastText: '#fff',
    },
    error: {
      light: 'rgba(247, 134, 159, 1)',
      main: 'rgba(239, 12, 62, 1)',
      dark: 'rgba(215, 11, 56, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(40, 42, 58, 1)',
      secondary: 'rgba(148, 149, 157, 1)',
      disabled: '#94959D',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});

export default theme;
