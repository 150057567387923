import theme from './Theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import '../node_modules/leaflet-geosearch/dist/geosearch.css';
import { AppEntrypoint } from './AppEntrypoint';


export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppEntrypoint />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
