import { makeStyles, withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

export const calendarStyles = () => {
  return makeStyles({
    filterContainer: {
      marginLeft: 'auto',
      minWidth: 100,
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      height: 'fit-content',
      width: '100%',
      backgroundColor: 'white',
      marginTop: 70,
    },
    loaderContainer: {
      backgroundColor: 'transparent',
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonContainer: {
      borderRadius: 4,
      overflow: 'hidden',
    },
    navigationButton: {
      color: '#FFFFFF',
      backgroundColor: '#282A3A',
      border: 'none',
      cursor: 'pointer',
      height: 'fit-content',
      '&:hover': {
        backgroundColor: '#20222E',
      },
    },
    navigationDate: {
      marginLeft: 30,
      marginRight: 30
    },
    labelContainer: {
      display: 'flex',
      columnGap: 2,
      borderRadius: 4,
      overflow: 'hidden',
      marginBottom: 4,
    },
    label: {
      width: 160,
      backgroundColor: '#2E5BFE',
      padding: '4px 0',
      textAlign: 'center',
      color: '#FFFFFF',
      boxSizing: 'border-box',
    },
    calendarContainer: {
      padding: '40px 0 60px',
    },
    calendar: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 160px)',
      gridGap: 2,
    },
    calendarField: {
      position: 'relative',
      height: 110,
      textAlign: 'center',
      boxSizing: 'border-box',
      backgroundColor: '#F4F4F5',
      overflowY: 'scroll',
      borderRadius: 8,
      padding: '36px 0 12px 0',
      '&::-webkit-scrollbar': {
        visibility: 'hidden',
        width: 1,
      },
    },
    current: {
      backgroundColor: '#EAEFFF',
    },
    date: {
      position: 'absolute',
      top: 2,
      right: 8,
      fontSize: 16,
      fontWeight: 'bolder',
      margin: '8px 8px 0 0',
      opacity: 0.2,
    },
    meetingInfo: {
      position: 'relative',
      padding: '4px 0 4px 16px',
      cursor: 'pointer',
      '&:not(:last-child)': {
        marginBottom: 2,
      }
    },
    meetingTime: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '&:not(:last-child)': {
        marginBottom: 2,
      },
    },
    noPadding: {
      padding: 0,
    },
    badge: {
      width: 10,
      height: 10,
      borderRadius: 20,
      marginRight: 8,
    },
    active: {
      backgroundColor: '#84D610',
    },
    canceled: {
      backgroundColor: '#EF0C3E',
    },
    start: {
      fontSize: 12,
      margin: '0',
    },
    name: {
      fontSize: 12,
      margin: '0 0 0 16px',
      textAlign: 'left',
      whiteSpace: 'normal'
    },
    meetingDetails: {
      background: 'white',
      width: 240,
      height: 'fit-content',
      zIndex: 3,
      color: 'black',
    },
    status: {
      fontSize: 12,
      margin: '0 0 10px 0',
    },
    host: {
      fontSize: 12,
    },
    button: {
      marginBottom: 10,
    },
  });
};

export const MeetingTooltip = withStyles({
  tooltip: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    boxShadow: '0 2px 6px 1px rgba(40, 42, 58, .1)',
  },
})(Tooltip);
