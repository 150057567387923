import { Edit, SimpleForm, ReferenceInput, SelectInput, TextInput, UrlField, required } from 'react-admin';
import TooltipField from '../TooltipInputField';
import UserSelectInput from './UserSelectInput';

export default function TeamEdit(props: any) {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput disabled source="id" sx={{width: "250px"}}/>
        <TooltipField source="slug" disabled color="grey" value="Slug is a suffix of your Team's name." />
        <TextInput source="name" validate={[required()]} sx={{width: "250px"}}/>
        <ReferenceInput label="Company" source="company" reference="companies" validate={[required()]}>
          <SelectInput optionText="name" sx={{width: "250px"}}/>
        </ReferenceInput>
        <UserSelectInput />
        <UrlField source="url" />
      </SimpleForm>
    </Edit>
  );
}
