import {
    required,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
} from 'react-admin';
import { FromToInput } from '../FromTo';
import { Stack, Typography } from '@mui/material';

const OpenHoursField = (props: any) => {
    return (
        <ArrayInput source={props.source} defaultValue={props.defaultValues ? props.defaultValues : undefined}>
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer subscription={{ values: true }}>
              {({ scopedFormData, getSource }) =>
                  scopedFormData &&
                  (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Stack direction="row" spacing={2} flex={1}>
                        <Typography style={{ marginTop: '20px', width: '100px'}} variant="body1">
                          {scopedFormData.weekday}
                        </Typography>
                        <BooleanInput label="Active" source={getSource('active')} defaultValue={scopedFormData.active}/>
                      </Stack>
                      {scopedFormData.active && (
                        <Stack direction="row" flex={1}>
                          <FromToInput
                            label="From"
                            source={getSource('from')}
                            defaultValue={scopedFormData.from}
                            validate={scopedFormData.active && [required()]}
                          />
                          <FromToInput
                            label="To"
                            source={getSource('to')}
                            defaultValue={scopedFormData.to}
                            disabled={!scopedFormData.active}
                            validate={scopedFormData.active ? [required()] : []}
                          />
                        </Stack>
                      )}
                    </Stack>
                  )
              }
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
    )
}

export default OpenHoursField;