import { 
  Filter, 
  TextInput, 
  SelectInput, 
  downloadCSV, 
  useRecordContext, 
  ArrayField, 
  useListContext, 
  Link as AdminLink, 
  useListController 
} from 'react-admin';
import { Datagrid, EmailField, List } from 'react-admin';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import DateTimeField from '../DateTimeField/DateTimeField';
import { containerStyles } from '../../styles';
import { IActionChoice, IProps } from '../../types/action';
import { unparse } from 'papaparse'
import ListContainer from '@mui/material/List';
import { ListItem } from '@mui/material';


const ACTION_SELECT: IActionChoice[] = [
  { id: 1, name: 'Cancel' },
  { id: 2, name: 'Reschedule' },
  { id: 3, name: 'Reassign' },
  { id: 4, name: 'Reassign All' },
  { id: 5, name: 'Destroy' },
];


const ActionField = (props: any) => {
  const record = useRecordContext();
  const { select, source, emptyText, alwaysOn, resettable }: IProps = props;
  const text = ACTION_SELECT.find((a: IActionChoice) => a.id === record?.action)?.name || 'Unknown';

  return select ? (
    <SelectInput
      choices={ACTION_SELECT}
      emptyText={emptyText}
      source={source}
      alwaysOn={alwaysOn}
      resettable={resettable}
      helperText=""
    />
  ) : (
    <Chip label={text} />
  );
};

const ActionFilter = (props: any) => {
  const styles = containerStyles();
  const { setFilters } = useListController();

  const clearFields = () => {
    setFilters({}, []);
  };

  return (
    <div className={styles.container}>
      <Filter {...props}>
        <TextInput source="user_email" label="Performer" type="email" alwaysOn resettable />
        <TextInput source="meeting__host__email" label="Meeting host" type="email" alwaysOn resettable />
        <ActionField select emptyText="Any" source="action" alwaysOn resettable />
      </Filter>
      {props.context === 'button' ? (
        ''
      ) : (
        <Button variant="outlined" color="primary" onClick={clearFields}>
          Clear Filters
        </Button>
      )}
    </div>
  );
};

const exporter =  (data: any) => {
  const dataForExport = data.map((dat: any) => {
      dat.action = ACTION_SELECT.find((a: IActionChoice) => a.id === dat.action)?.name || 'Unknown';
      return dat;
  });
  console.log(dataForExport);
  const csv = unparse({
      data: dataForExport,
      fields: ["id", "meeting__uuid", "user_email", "action", "created_at", "meeting__host", "meeting__invitees", "meeting__start"] // order fields in the export
  });
  downloadCSV(csv, 'calendars_meeting-action-logs'); // download as 'posts.csv` file
}

const ArrayItemField = () => {
  const { data } = useListContext();

  return (
    <ListContainer>
      {data.map((id) => (
        <ListItem key={id}>
          <AdminLink to={`mailto:${id}`}>{id}</AdminLink>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default function ActionList(props: any) {
  return (
    <List {...props} title="Actions" filters={<ActionFilter />} exporter={exporter} >
      <Datagrid bulkActionButtons={false}>
        <EmailField source="user_email" label="Performer" />
        <DateTimeField source="meeting__start" label="Meeting date" fieldKey="meeting__start" />
        <EmailField source="meeting__host" label="Meeting host" />
        <ArrayField source="meeting__invitees" sortable={false} label="Invitees">
          <ArrayItemField />
        </ArrayField>
        <ActionField label="Action" />
        <DateTimeField source="created_at" label="Action date" fieldKey="created_at" />
      </Datagrid>
    </List>
  );
}
