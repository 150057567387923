import { Card, CardContent, IconButton, List, ListItem, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material"

import GroupsIcon from '@mui/icons-material/Groups';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useState } from "react";

export const PremiumPlanPopup = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <IconButton onMouseEnter={handleEnter} onMouseLeave={handleClose} size="large">
            <PaidIcon />
        </IconButton>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
                pointerEvents: 'none',
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            disableRestoreFocus>
            <PremiumPlanDescription />
        </Popover>
    </>;
}

export const PremiumPlanDescription = (props: any) => {

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="div">
                    Premium plan for Your own benefit.
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <PeopleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Adding up to 2 free users to Your company and teams" />
                    </ListItem >
                    <ListItem>
                        <ListItemIcon>
                            <GroupsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Easly schedule meetings for your teams" />
                    </ListItem >
                    <ListItem>
                        <ListItemIcon>
                            <ShoppingCartIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create paid meetings" />
                    </ListItem >
                    <ListItem>
                        <ListItemIcon>
                            <EventAvailableIcon />
                        </ListItemIcon>
                        <ListItemText primary="Integrate with unlimited number of Google calendars" />
                    </ListItem >
                </List>
                <b> You can resign at any time.</b>
            </CardContent>
        </Card>
    )
}