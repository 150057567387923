import { BaseSyntheticEvent, useState } from 'react';
import { Create, SimpleForm, SelectInput, ReferenceInput } from 'react-admin';
import Button from '@mui/material/Button';

import './meeting.css';

const CustomToolbar = (props: any) => {
  const { slugId } = props;
  return (
    <div className="fake-toolbar">
      <Button
        href={`${process.env.REACT_APP_FRONTEND_URL}/meetings/${slugId}/new`}
        target="_blank"
        variant="contained"
        color="primary"
        disabled={slugId ? false : true}
      >
        Create
      </Button>
    </div>
  );
};

const SlugSelectInput = (props: any) => {
  const { handleSlugId } = props;
  return (
    <SelectInput
      optionText="name"
      inputProps={{
        onChange: ({ target: { value } }: BaseSyntheticEvent) => handleSlugId(value),
      }}
      sx={{width: "250px"}}
    />
  );
};

export default function MeetingCreate(props: any) {
  const [slugId, setSlugId] = useState<number>();
  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolbar slugId={slugId} />}>
        <ReferenceInput reference="calendars/event-types" source="slug">
          <SlugSelectInput handleSlugId={setSlugId} label="Event type"/>
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}
