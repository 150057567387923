import { HttpResponse, IUserResult, IUser } from '../../types';
import { ICompanyResult } from './company';
import { COMPANIES_URL, userGetByCompanyIdUrl, userReassignAllUrl, removeGoogleCalendarUrl } from './endpoints';
import http from './http';

export const userReassignAll = (id: number) =>
  http({
    url: userReassignAllUrl(id),
    handleError: true,
    putToken: true,
  });

export const userGetCompanies = (): Promise<HttpResponse<ICompanyResult>> =>
  http({
    url: COMPANIES_URL,
    handleError: true,
    putToken: true,
  });

export const userGetByCompanyId = (id: number): Promise<HttpResponse<IUserResult>> =>
  http({
    url: userGetByCompanyIdUrl(id),
    handleError: true,
    putToken: true,
  });

export const userGetById = (id: number): Promise<HttpResponse<IUser>> =>
  http({
    url: `/users/${id}`,
    handleError: true,
    putToken: true,
  });

export const removeGoogleCalendar = (email: string): Promise<HttpResponse> =>
  http({
    method: 'delete',
    url: removeGoogleCalendarUrl(email),
    handleError: true,
    putToken: true,
  });
