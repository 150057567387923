import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useInput } from 'react-admin';
import { useRecordContext } from 'ra-core';
import { useState } from 'react';
import dayjs from 'dayjs';

const BuforPicker = (props: any) => {
  const record = useRecordContext();
  const { ...rest } = props;
  const { field } = useInput({ ...rest });
  const [bufor, setBufor] = useState(record ? record.bufor : "00:05:00");

  const parseBuforToDayjs = (bufor:any) => {
    const [hours, minutes, seconds] = bufor.split(':');
    return dayjs().hour(parseInt(hours)).minute(parseInt(minutes)).second(parseInt(seconds));
  };

  const handleBuforChange = (newBufor:any) => {
    const strBufor = newBufor.format('HH:mm:ss');
    setBufor(strBufor);
    field.onChange(strBufor);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
          {...field}
          label="Bufor"
          value={parseBuforToDayjs(bufor)}
          onChange={(newValue) => handleBuforChange(newValue)}
          views={['minutes']}
          ampm={false}
          sx={{width: "100px"}}
          slotProps={{
            layout: {
              sx: {
                ul: {
                  '::-webkit-scrollbar': {
                    width: '2px',
                  },
                },
              },
            }
          }}
      />
    </LocalizationProvider>
  )
}

export default BuforPicker;