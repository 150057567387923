import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import UserSelectInput from './UserSelectInput';

export default function TeamCreate(props: any) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} sx={{width: "250px"}}/>
        <ReferenceInput label="Company" source="company" reference="companies" validate={[required()]}>
          <SelectInput optionText="name" sx={{width: "250px"}}/>
        </ReferenceInput>
        <UserSelectInput />
      </SimpleForm>
    </Create>
  );
}
