import React from 'react';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Box, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Modal from '@mui/material/Modal';
import { IChangelog } from '../../types';
import Divider from '@mui/material/Divider';
import { changelogGet } from '../../compositions/http/changelog';
import { SimpleForm, RichTextField } from 'react-admin';

const ChangelogPopup = (props: any) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };
  const [changelog, setChangelog] = useState<IChangelog>();
  
  useEffect(() => {
    changelogGet()
      .then((data) => {
        const results = data.data.results
        setChangelog(results[results.length - 1])
      })
      .catch((error) => {
        console.error(error)
      })
  }, []);

  return (
    <div>
        {changelog? (
          <Typography variant='body2'>{changelog.version}
            <IconButton onClick={handleOpen} style={{color: "white"}} size="large"><InfoIcon/></IconButton>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Release date: {changelog.released_at.slice(0, 10)}
                </Typography>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Changes:
                </Typography> &nbsp; <Divider /> &nbsp;
                <SimpleForm record={changelog} toolbar={<></>}>
                  <RichTextField source="content" label="Changes" />
                </SimpleForm>
              </Box>
            </Modal>
          </Typography>
        ) : null}
    </div>
  );
};
  
export default ChangelogPopup;