import { useEffect, useState } from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  useRedirect,
  useNotify,
  usePermissions
} from 'react-admin';
import { getTokenInfo, postPrivateEventType, postPrivateEventTypeDetails } from '../../compositions/http';
import { currentTimezone, timezones, defaultWeekdays } from '../../resources';
import { ErrorResponse, OpenTime } from '../../types';
import DurationInput from '../Duration';
import { OpenHoursField } from '../FromTo';
import BuforPicker from '../BuforPicker';
import { LocationInput, PaidMeetingInput, TeamMeetingInput, RecurrenceInput } from './EventFormInputs';
import { RichTextInput } from 'ra-input-rich-text';

interface PrivateEventTypesFormValues {
  name: string;
  description: string;
  duration: string;
  location: 1 | 3;
  open_time: OpenTime[];
  timezone: string;
}

export default function PrivateEventTypesCreate(props: any) {

  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])


  const notify = useNotify();
  const redirect = useRedirect();
  const [calendars, setCalendars] = useState<{ name: string; id: number }[]>([]);

  const handleSave = (values: PrivateEventTypesFormValues) => {
    const { open_time: private_time_availability, timezone: private_time_zone, ...payload } = values;
    const privateEventTypeDetailsPayload = { private_time_availability, private_time_zone };

    postPrivateEventTypeDetails(privateEventTypeDetailsPayload)
      .then((r) => {
        const { id } = r.data as { id: number };
        const eventTypePayload = {
          ...payload,
          team: null,
          private: true,
          private_event_details: id,
        };
        postPrivateEventType(eventTypePayload)
          .then((_) => {
            redirect('/calendars/event-types');
            return;
          })
          .catch((_e: ErrorResponse) => {
            notify("Couldn't create private event type");
          });
      })
      .catch((e) => {
        notify(e);
      });
  };

  useEffect(() => {
    getTokenInfo()
      .then(({ data }: { data: any }) => {
        setCalendars([...data.user.calendars]);
      })
      .catch((_e) => {
        notify('Could not fetch calendars. Please reload.');
      });
  }, [notify]);

  return (
    <Create {...props}>
      <SimpleForm {...props} onSubmit={handleSave}>
        <TextInput source="name" validate={required()} sx={{width: "250px"}}/>
        <SelectInput choices={calendars} optionText="name" optionValue="id" source="calendar" sx={{width: "250px"}}/>
        <RichTextInput source="description" validate={required()} sx={{width: "800px"}}/>
        <DurationInput source='duration' defaultValue={"01:00:00"}/>
        <BuforPicker source='bufor' />
        <ArrayInput source="questions">
          <SimpleFormIterator>
            {/* @ts-ignore: Unreachable code error */}
            <TextInput label="Question" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <TeamMeetingInput isFreeAccout={isFreeAccout} />
        <PaidMeetingInput isFreeAccout={isFreeAccout} />
        <LocationInput />
        <SelectInput source="timezone" choices={timezones} defaultValue={currentTimezone} label="Time Zone" sx={{width: "250px"}}/>
        <OpenHoursField source="open_time" defaultValues={defaultWeekdays}/>
        <RecurrenceInput />
      </SimpleForm>
    </Create>
  );
}
