import { fetchUtils } from 'ra-core';
import inMemoryJWT from './inMemoryJWT';

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const token = inMemoryJWT.getToken();

    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    } else {
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
            };
            return fetchUtils.fetchJson(url, options);
        });
    }
};

export const getAuthHeader = () => {
    const token = inMemoryJWT.getToken();
    if (token) {
        return { Authorization: `Bearer ${token}` }
    } else {
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                return Promise.resolve({ Authorization: `Bearer ${token}` })
            };
            return Promise.resolve({})
        });
    }
}

