import Button, { ButtonProps } from "@mui/material/Button";
import React, { useState, useEffect } from 'react';
import { getTokenInfo, http } from '../../compositions/http';
import authProvider from "../../compositions/jwt/jwtAuthProvider";


const ConnectGoogleCalendarButton = () => {
  // eslint-disable-next-line
  const [buttonText, setButtonText] = useState("Account not verified")
  const [buttonVariant, setButtonVariant] = useState<ButtonProps['variant']>('outlined')
  const key = authProvider.getToken()
  const [verified, setVerified] = useState(true)

  const handleEnter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonText("Add Google Calendar")
    setButtonVariant("contained")
  };

  const handleClose = () => {
    setButtonText("Account not verified")
    setButtonVariant("outlined")
  };

  useEffect(() => {
    getTokenInfo()
      .then((data) => {
        http({
          method: 'put',
          url: `/users/verify/?userId=${data.data.user.id}`
        })
          .then(response => response.data)
          .then(data => {
            setVerified(true);
          })
          .catch((error) => {
            console.error("Error", error);
          });
      });
    // eslint-disable-next-line
  }, []);

  return (
    !verified ?
      <Button
        onMouseEnter={handleEnter}
        onMouseLeave={handleClose}
        href={`${process.env.REACT_APP_BACKEND_URL}/users/add-google-calendar/?token=${key}`}
        variant={buttonVariant}
        size="small"
        color="error"
        style={{ marginRight: '10px' }}
      >
        {buttonText}
      </Button>
      : null
  )
}

export default (ConnectGoogleCalendarButton);