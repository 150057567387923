import { Create, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from "react-admin";

export const CreateEventGroups = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" sx={{width: "250px"}}/>
            <ReferenceArrayInput source="event_types" reference="calendars/event-types">
                <SelectArrayInput optionText="name" sx={{width: "250px"}}/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create >
);