import React, { useState } from 'react';
import { useLogin, useNotify, useTranslate } from 'react-admin';
import { useForm, useController } from 'react-hook-form';
import { withStyles, createStyles } from '@mui/styles';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

const styles = ({ spacing }: any) =>
  createStyles({
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
  });

const LoginForm = ({ classes, redirectTo }: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const login = useLogin();
  const notify = useNotify();
  const { control, handleSubmit } = useForm();

  const submit = (values: any) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {type: 'warning'},
        );
      });
  };

  const { field: usernameInput, fieldState: usernameInputState} = useController(
    {name: 'username', control, rules: {required: "Username is required"}, defaultValue: ''});
  
  const { field: passwordInput, fieldState: passwordInputState} = useController(
    {name: 'password', control, rules: {required: "Password is required"}, defaultValue: ''});

  return (
        <form onSubmit={handleSubmit(submit)} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <TextField
                {...usernameInput}
                error={!!(usernameInputState.isTouched && usernameInputState.error)}
                helperText={usernameInputState.isTouched && usernameInputState.error?.message}
                autoFocus
                fullWidth
                label={"Email"}
                disabled={loading}
              />
            </div>
            <div className={classes.input}>
              <TextField
                {...passwordInput}
                error={!!(passwordInputState.isTouched && passwordInputState.error)}
                helperText={passwordInputState.isTouched && passwordInputState.error?.message}
                autoFocus
                fullWidth
                label={translate('ra.auth.password')}
                autoComplete='current-password'
                disabled={loading}
                type='password'
              />
            </div>
          </div>
          <CardActions>
            <Button variant="contained" type="submit" color="primary" disabled={loading} className={classes.button}>
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
          <CardActions>
          <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={loading}
              href={`${process.env.REACT_APP_FRONTEND_URL}/signup`}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              Create Account
            </Button>
            </CardActions>
          <CardActions>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={loading}
              href={`${process.env.REACT_APP_BACKEND_URL}/users/admin-panel-login/?provider=google`}
            >
              {loading && <CircularProgress className={classes.icon} size={18} thickness={2} />}
              Login With Google
            </Button>
          </CardActions>
        </form>
  );
};

export default withStyles(styles)(LoginForm);
