import Chip from '@mui/material/Chip';
import { useRecordContext } from 'react-admin';

export default function FromToField({ source }: any) {
  const record = useRecordContext();
  const hasNoHours = record['from'] === null && record['to'] === null;
  const inactiveField = source === 'from' ? <Chip size="small" label="Inactive" /> : null;

  return <span>{!hasNoHours && record.active ? record[source] : inactiveField}</span>;
}
