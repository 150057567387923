import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  usePermissions,
} from 'react-admin';
import DurationInput from '../Duration';
import { validateUrl } from '../../compositions';
import TooltipField from '../TooltipInputField';
import BuforPicker from '../BuforPicker';
import { useEffect, useState } from 'react';
import { GroupMeetingInput, LocationInput, PaidMeetingInput, TeamMeetingInput, RecurrenceInput } from './EventFormInputs';
import { RichTextInput } from 'ra-input-rich-text';
import { Stack } from '@mui/material';

export default function EventTypesEdit(props: any) {
  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])

  return (
    <Edit {...props} mutationMode="pessimistic">
      <SimpleForm toolbar={<Toolbar><SaveButton alwaysEnable/></Toolbar>}>
        <TextInput disabled source="id" validate={required()} sx={{width: "250px"}}/>
        <TextInput source="name" validate={required()} sx={{width: "250px"}}/>
        <TooltipField source="slug" disabled color="grey" value="Slug is a suffix of your Event Type's name." />
        <TooltipField
          source="url"
          validate={[required(), validateUrl]}
          disabled
          color="grey"
          value="Url is an address for accessing your Event Type in the app."
        />
        <ReferenceInput label="Team" source="team" reference="teams" validate={[required()]}>
          <SelectInput optionText="name" sx={{width: "250px"}}/>
        </ReferenceInput>
        <RichTextInput source="description" validate={required()} sx={{width: "800px"}}/>
        <Stack direction="row" spacing={1}>
          <DurationInput source='duration' />
          <BuforPicker source='bufor' />
        </Stack>
        <ArrayInput source="questions">
          <SimpleFormIterator>
            {/* @ts-ignore: Unreachable code error */}
            <TextInput label="Question" validate={required()} sx={{width: "250px"}}/>
          </SimpleFormIterator>
        </ArrayInput>
        <TeamMeetingInput isFreeAccout={isFreeAccout} />
        <PaidMeetingInput isFreeAccout={isFreeAccout} />
        <GroupMeetingInput isFreeAccout={isFreeAccout} />
        <LocationInput />
        <RecurrenceInput />
      </SimpleForm>
    </Edit>
  );
}
