import { Box, Grid,  } from '@mui/material';
import Link from '@mui/material/Link';
import ChangelogPopup from '../components/changelog/ChangelogPopup'

const CustomFooter = (props: any) => {

  return (
    <Box className="footer">
      <Grid container spacing={1} alignItems="center" color="white" p={2}>
        <Grid item xs={12}>
          <ChangelogPopup/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link target='blank' variant='body2' underline='none' style={{color: 'white'}}
          href={process.env.REACT_APP_FRONTEND_URL + '/terms-of-use'}> Terms of use </Link>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Link target='blank' variant='body2' underline='none' style={{color: 'white'}}
          href={process.env.REACT_APP_FRONTEND_URL + '/privacy-policy'}> Privacy policy </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
  
export default CustomFooter;