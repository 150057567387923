import { useEffect, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CircularProgress from '@mui/material/CircularProgress';

import { getTokenInfo } from '../../compositions/http';
import { getMeetings, getGoogleCalendarMeetings } from '../../compositions/http';
import { ICalendarField, TObject, UserCalendar } from '../../types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useNotify } from 'react-admin';
import { Meeting } from '../Calendar/Meeting';



export default function Calendar() {

    const [filters, setFilters] = useState<TObject>({});
    const [calendarData, setCalendarData] = useState({});
    const [showGoogleCalendarChecked, setGoogleCalendarChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [calendars, setCalendars] = useState<UserCalendar[]>([]);

    const notify = useNotify();

    const handleGoogleCalendarChange = () => {
        setGoogleCalendarChecked(!showGoogleCalendarChecked);
    };

    const handleCalendarChange = (event: any) => {
        setFilters({ host: event.target.value });
    };

    const handleEventClick = (e: any) => {
        e.jsEvent.cancelBubble = true;
        e.jsEvent.preventDefault();
    }

    useEffect(() => {
        getTokenInfo()
            .then((r: any) => {
                setCalendars(r.data.user.calendars);
            })
            .catch(() => {
                notify('Could not fetch calendars');
            });
    }, [notify]);

    useEffect(() => {
        const fetchMeetMeetings = () => {
            getMeetings(filters)
                .then(({ data: { results } }) => {
                    const newCalendarData = results.map((result) => {

                        const start = new Date(result.start);
                        const end = new Date(result.start);
                        end.setHours(end.getHours() + 1);

                        return {
                            id: result.uuid,
                            title: result.event_type_slug,
                            start: start.toISOString(),
                            end: end.toISOString(),
                            status: result.status,
                            url: result.url,
                            host: result.host,
                            location: result.location,
                        };
                    });

                    setCalendarData(newCalendarData);
                })
                .catch((e) => {
                });
        }

        const fetchGoogleCalendarMeetings = () => {
            if (showGoogleCalendarChecked) {
                getGoogleCalendarMeetings(filters)
                    .then(({ data }) => {
                        setCalendarData(data);
                    }).catch((e) => console.log("Error"))
            }
        }

        const fetchMeetings = () => {
            if (!showGoogleCalendarChecked) {
                fetchMeetMeetings();
            }
            else {
                fetchGoogleCalendarMeetings();
            }
        }

        setIsLoading(false);
        fetchMeetings();
        setIsLoading(false);

    }, [filters, showGoogleCalendarChecked]);


    return (
        <div>
            {isLoading ?
                (<CircularProgress />) :
                <div>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Calendar</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filters.host}
                                label="Age"
                                onChange={handleCalendarChange}>
                                <MenuItem value="">All</MenuItem>
                                {calendars.map((c) => (
                                    <MenuItem key={`calendar-${c.id}`} value={c.name}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <input
                            type="checkbox"
                            checked={showGoogleCalendarChecked}
                            onChange={handleGoogleCalendarChange}
                        />
                        Show all Google Calendar meetings
                    </div>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            center: 'dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        events={calendarData}
                        nowIndicator
                        initialView="dayGridMonth"
                        eventClick={(e) => handleEventClick(e)}
                        eventContent={(details) => {
                            const event_info = {
                                status: details.event.extendedProps.status,
                                start: details.event.start?.toUTCString(),
                                uuid: details.event.id,
                                url: details.event.url,
                                host: details.event.extendedProps.host,
                                name: details.event.title,
                                location: details.event.extendedProps.location,
                            } as ICalendarField;
                            return (
                                <Meeting key={event_info.uuid} {...event_info} />
                            );
                        }}
                    />
                </div>
            }
        </div>
    );
}

