import {
  Create,
  required,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  usePermissions,
} from 'react-admin';
import DurationInput from '../Duration';
import BuforPicker from '../BuforPicker';
import { useEffect, useState } from 'react';
import { GroupMeetingInput, LocationInput, PaidMeetingInput, TeamMeetingInput, RecurrenceInput } from './EventFormInputs';
import { RichTextInput } from 'ra-input-rich-text';
import { Stack } from '@mui/material';

export default function EventTypesCreate(props: any) {
  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" validate={required()} sx={{width: "250px"}}/>
        <ReferenceInput source="team" reference="teams" validate={[required()]} perPage={1000}>
          <SelectInput optionText="name" label="Team" sx={{width: "250px"}}/>
        </ReferenceInput>
        <RichTextInput source="description" validate={required()} sx={{width: "800px"}}/>
        <Stack direction="row" spacing={1}>
          <DurationInput source='duration' defaultValue={"01:00:00"}/>
          <BuforPicker source='bufor' />
        </Stack>
        <ArrayInput source="questions">
          <SimpleFormIterator sx={{ marginTop: '20px' }} >
            {/* @ts-ignore: Unreachable code error */}
            <TextInput label="Question" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <TeamMeetingInput isFreeAccout={isFreeAccout} />
        <PaidMeetingInput isFreeAccout={isFreeAccout} />
        <GroupMeetingInput isFreeAccout={isFreeAccout} />
        <LocationInput source='location'/>
        <RecurrenceInput />
      </SimpleForm>
    </Create>
  );
}
