import {
  Edit,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  useRedirect,
  useNotify,
  usePermissions,
} from 'react-admin';
import DurationInput from '../Duration';
import { validateUrl } from '../../compositions';
import TooltipField from '../TooltipInputField';
import { currentTimezone, timezones, defaultWeekdays } from '../../resources';
import { OpenHoursField } from '../FromTo';
import {
  getPrivateEventTypeDetails,
  putPrivateEventType,
  putPrivateEventTypeDetails,
  getTokenInfo,
} from '../../compositions/http';
import { ErrorResponse, OpenTime, UserCalendar } from '../../types';
import { useEffect, useState } from 'react';
import BuforPicker from '../BuforPicker';
import { LocationInput, PaidMeetingInput, TeamMeetingInput, RecurrenceInput } from './EventFormInputs';
import { RichTextInput } from 'ra-input-rich-text';
import { useParams } from 'react-router-dom';

interface PrivateEventDetail {
  private_time_zone: string;
  private_time_availability: OpenTime[];
}

export default function PrivateEventTypesEdit(props: any) {
  const { id } = useParams();
  const numId = Number(id);

  const redirect = useRedirect();
  const notify = useNotify();

  const [details, setDetails] = useState<PrivateEventDetail>({
    private_time_availability: defaultWeekdays,
    private_time_zone: currentTimezone,
  });

  const [calendars, setCalendars] = useState<UserCalendar[]>([]);

  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])

  useEffect(() => {
    getPrivateEventTypeDetails(numId)
      .then((r) => {
        const data = r.data as PrivateEventDetail;
        setDetails({ ...data });
      })
      .catch((_) => {
        notify('Failed to fetch event type details.');
      });

    getTokenInfo()
      .then((r) => {
        setCalendars([...r.data.user.calendars]);
      })
      .catch((_) => {
        notify('Failed to fetch calendars.');
      });

    // eslint-disable-next-line
  }, [numId]);

  const handleSave = (values: any) => {
    const { private_time_availability, private_time_zone, ...payload } = values;
    const privateEventTypeDetailsPayload = { private_time_availability, private_time_zone };
    putPrivateEventTypeDetails(privateEventTypeDetailsPayload, numId)
      .then((r) => {
        const { id: detailsId } = r.data as { id: number };
        const eventTypePayload = {
          ...payload,
          team: null,
          private: true,
          private_event_details: detailsId,
        };
        putPrivateEventType(eventTypePayload, numId)
          .then((_) => {
            redirect('/calendars/event-types');
            return;
          })
          .catch((_e: ErrorResponse) => {
            notify("Couldn't create private event type");
          });
      })
      .catch((e) => {
        notify(e);
      });
  };

  return (
    <Edit {...props} mutationMode="undoable">
      <SimpleForm toolbar={<Toolbar><SaveButton alwaysEnable/></Toolbar>} onSubmit={handleSave} defaultValues={{ ...details }}>
        <TextInput disabled source="id" validate={required()} sx={{width: "250px"}}/>
        <TextInput source="name" validate={required()} sx={{width: "250px"}}/>
        <SelectInput source="calendar" optionText="name" optionValue="id" choices={calendars} sx={{width: "250px"}}/>
        <TooltipField source="slug" disabled color="grey" value="Slug is a suffix of your Event Type's name." />
        <TooltipField
          source="url"
          validate={[required(), validateUrl]}
          disabled
          color="grey"
          value="Url is an address for accessing your Event Type in the app."
        />
        <BooleanInput source="private" defaultValue={true} />
        <RichTextInput source="description" validate={required()} sx={{width: "800px"}}/>
        <DurationInput source='duration' />
        <BuforPicker source='bufor' />
        <ArrayInput source="questions">
          <SimpleFormIterator>
            {/* @ts-ignore: Unreachable code error */}
            <TextInput label="Question" validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <TeamMeetingInput isFreeAccout={isFreeAccout} />
        <PaidMeetingInput isFreeAccout={isFreeAccout} />
        <LocationInput />
        <SelectInput
          source="private_time_zone"
          choices={timezones}
          defaultValue={details.private_time_zone}
          value={details.private_time_zone}
          sx={{width: "250px"}}
        />
        <OpenHoursField source="private_time_availability"/>
        <RecurrenceInput />
      </SimpleForm>
    </Edit>
  );
}
