import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EmailField,
  ArrayField,
  useListContext,
  Link as AdminLink,
  Filter,
  TextInput,
  SelectInput,
  useNotify,
  useRefresh,
  useRecordContext,
  usePermissions,
  useListController,
} from 'react-admin';
import ListContainer from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { makeStyles, withStyles } from '@mui/styles';
import Link from '../Link';
import Tooltip from '@mui/material/Tooltip';
import MoreIcon from '@mui/icons-material/MoreHorizOutlined';
import DateTimeField from '../DateTimeField/DateTimeField';
import { getReassignHosts, meetingDelete, meetingReassign, getError, getEventData, http } from '../../compositions/http';
import { containerStyles } from '../../styles';
import { IReassignButtonProps, IReassignFieldAttrs, TPossibleHosts } from '../../types';
import authProvider from '../../compositions/jwt/jwtAuthProvider';

const ActionsTooltip = withStyles({
  tooltip: {
    backgroundColor: '#F4F4F5',
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 6px',
    boxShadow: '0 2px 6px 1px rgba(40, 42, 58, .1)',
  },
})(Tooltip);

const buttonStyles = makeStyles({
  tooltipButton: {
    color: 'black',
    backgroundColor: 'transparent',
    borderRadius: 8,
    padding: '6px 14px',
    marginBottom: 4,
    '&.Mui-disabled': {
      backgroundColor: '#F4F4F5',
      color: '#94959D',
    },
    '&:hover': {
      backgroundColor: '#EAEFFF',
      color: '#2E5BFE',
      boxShadow: '0 2px 3px 0px rgba(40, 42, 58, .1)',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  tooltipDeleteButton: {
    color: '#EF0C3E',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#FDE7EC',
      boxShadow: '0 2px 3px 0px rgba(40, 42, 58, .1)',
    },
    '&:not(:last-child)': {
      marginBottom: 4,
    },
  },
  hostButton: {
    color: 'black',
    backgroundColor: 'transparent',
    textTransform: 'none',
    height: 'fit-content',
    '&:hover': {
      backgroundColor: '#EAEFFF',
      color: '#2E5BFE',
    },
  },
});

const ButtonDeleteField = () => {
  const { uuid } = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const styles = buttonStyles();

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this meeting?')) {
      meetingDelete(uuid)
        .then(() => {
          notify('Meeting has been deleted');
          refresh();
        })
        .catch((e) => {
          const error = getError(e);
          notify(error.message);
        });
    }
  };

  return (
    <Button className={styles.tooltipDeleteButton} onClick={handleDelete} size="small">
      Delete
    </Button>
  );
};

const ButtonEditField = () => {
  const { uuid } = useRecordContext();
  const styles = buttonStyles();
  let key = authProvider.getToken();

  return (
    <Button
      className={styles.tooltipButton}
      href={`${process.env.REACT_APP_FRONTEND_URL}/edit/${uuid}/?key=${key}`}
      target="_blank"
      size="small"
    >
      Edit
    </Button>
  );
};

const ButtonCancelField = (attrs: any) => {
  const {
    canceled,
    handleCanceled,
  }: {
    canceled: boolean;
    handleCanceled: React.Dispatch<React.SetStateAction<boolean>>;
  } = attrs;
  const { uuid } = useRecordContext();
  const styles = buttonStyles();
  const notify = useNotify();

  const handleCancel = () => {
    if (window.confirm('Are you sure you want to cancel this meeting?')) {
      http({
        url: `${process.env.REACT_APP_BACKEND_URL}/calendars/meetings/${uuid}/cancel/`,
        options: {
          redirect: 'manual'

        }
      })
        .then((r) => {
          if (r.type === 'opaqueredirect') {
            notify('Meeting canceled');
            handleCanceled(true);
          } else {
            notify(`Canceling failed with status ${r.status}`);
          }
        })
        .catch((e) => {
          const error = getError(e);
          notify(error.message);
        });
    }
  };
  return (
    <Button className={styles.tooltipButton} onClick={handleCancel} disabled={canceled} size="small">
      {canceled ? 'Canceled' : 'Cancel'}
    </Button>
  );
};

const ReassignField = (attrs: any) => {
  const { hosts }: IReassignFieldAttrs = attrs;
  const { uuid } = useRecordContext();
  const styles = buttonStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const reassign = (hostId: number) => {
    meetingReassign(uuid, { host_id: hostId })
      .then(() => {
        notify('Meeting has been reassigned');
        refresh();
      })
      .catch((e: string) => {
        const error = getError(e);
        notify(error.message);
      });
  };

  const ReassignButton = ({ id, host }: IReassignButtonProps) => {
    return (
      <Button className={styles.hostButton} onClick={() => reassign(id)} size="small">
        {host}
      </Button>
    );
  };

  const TooltipContent = () => (
    <>
      {hosts && hosts.map((h) => (
        <ReassignButton id={h.id} host={h.full_name} />
      ))}
    </>
  );

  return (
    <ActionsTooltip title={<TooltipContent />} placement="left-end">
      <span>
        <Button className={styles.tooltipButton} size="small">
          Reassign
        </Button>
      </span>
    </ActionsTooltip>
  );
};

const ActionsField = (attrs: any) => {
  const { uuid, status, event_type, host } = useRecordContext();
  const [possibleHosts, setPossibleHosts] = useState<TPossibleHosts[]>([]);
  const [canceled, setCanceled] = useState<boolean>(status === 2);
  const [groupEvent, setGroupEvent] = useState(false);
  const { permissions } = usePermissions();

  useEffect(() => {
    getEventData(event_type)
      .then(({ data }) => {
        setGroupEvent(data.group_event);
      });
    getReassignHosts(uuid)
      .then(({ data }) => {
        setPossibleHosts([...data.available_hosts]);
      })
      .catch(() => {
        setPossibleHosts([]);
      });
  }, [uuid, event_type, host, permissions.id]);

  const TooltipContent = () => (
    <>
      {groupEvent ? null : <ButtonEditField label="Edit" source="uuid" sortable={false} {...attrs} />}
      <ButtonCancelField
        label="Cancel"
        source="uuid"
        sortable={false}
        canceled={canceled || status === 2}
        handleCanceled={setCanceled}
        {...attrs}
      />
      <ButtonDeleteField label="Delete" source="uuid" sortable={false} {...attrs} />
      {!!possibleHosts && status !== 2 && <ReassignField hosts={possibleHosts} {...attrs} />}
    </>
  );

  return (
    <ActionsTooltip title={<TooltipContent />} placement="left-start">
      <MoreIcon />
    </ActionsTooltip>
  );
};

const MeetingFilter = (props: any) => {
  const styles = containerStyles();
  const { setFilters } = useListController();

  const statusChoices = [
    { id: 1, name: 'Scheduled' },
    { id: 2, name: 'Canceled' },
  ];

  const clearFields = () => {
    setFilters({}, []);
  };

  const { permissions } = usePermissions();

  return (
    <div className={styles.container}>
      <Filter {...props}>
        <TextInput source="host" alwaysOn resettable />
        {/* <TextInput source="invitees" alwaysOn resettable /> */}
        {permissions?.role !== 2 && (
          <TextInput source="event_type__slug" label="Slug" alwaysOn resettable />
        )}
        {permissions?.role !== 2 && (
          <TextInput source="event_type__team__slug" label="Team's slug" resettable />
        )}
        {permissions?.role !== 2 && (
          <TextInput source="event_type__team__company__slug" label="Company's slug" resettable />
        )}
        <SelectInput choices={statusChoices} emptyText="Any" source="status" resettable />
      </Filter>
      {props.context === 'button' ? (
        ''
      ) : (
        <Button variant="outlined" color="primary" onClick={clearFields} sx={{ "width": "200px" }}>
          Clear Filters
        </Button>
      )}
    </div>
  );
};

const ArrayItemField = () => {
  const { data } = useListContext();

  return (
    <ListContainer>
      {data.map((id) => (
        <ListItem key={id}>
          <AdminLink to={`mailto:${id}`}>{id}</AdminLink>
        </ListItem>
      ))}
    </ListContainer>
  );
};

export default function MeetingsList(props: any) {
  const { permissions } = usePermissions();
  return (
    <List {...props}
      filters={<MeetingFilter />}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        {permissions?.role !== 2 && (
          <ReferenceField label="Slug" source="event_type" reference="calendars/event-types">
            <TextField source="name" />
          </ReferenceField>
        )}
        <EmailField source="host" />
        <DateTimeField label="Start time" source="start" fieldKey="start" />
        <ArrayField source="invitees" sortable={false} >
          <ArrayItemField />
        </ArrayField>
        <ArrayField source="answers" sortable={false} >
          <Datagrid bulkActionButtons={false} empty={<p></p>} >
            <TextField source="question" />
            <TextField source="answer" />
          </Datagrid>
        </ArrayField>
        <Link source="url" sortable={false} />
        <ActionsField label="Actions" />
      </Datagrid>
    </List>
  );
}
