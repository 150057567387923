import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  ReferenceField,
  useNotify,
  Filter,
  TextInput,
  SelectInput,
  SingleFieldList,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  useRecordContext,
  ExportButton,
  CreateButton,
  TopToolbar,
  usePermissions,
  useGetList,
  FunctionField,
  useListController,
} from 'react-admin';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { http, userReassignAll } from '../../compositions/http';
import { getError } from '../../compositions/http';
import { CircularProgress, Stack } from '@mui/material';
import { maxFreeUsersLimit } from '../../types/membership_constants';
import { PremiumPlanPopup } from '../stripePayments/PlansDescriptions';

import { MembershipChoices } from '../../types/membership_constants';

const usersStyles = () => {
  return makeStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: '10px',
    },
    teamsInput: {
      minWidth: '100px',
      '& div': {
        maxHeight: '48px',
        '& div': {
          maxHeight: '20px',
          marginBottom: '5px',
        },
      },
    },
  });
};

const ReassignAllField = () => {
  const { id } = useRecordContext();
  const [disabled, setDisabled] = useState(false);
  const notify = useNotify();

  const reassignAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    userReassignAll(id as number)
      .then(() => {
        notify("User's meetings have been reassinged");
        setDisabled(true);
      })
      .catch((e) => {
        const error = getError(e);
        notify(error.message);
      });
  };
  return (
    <Button disabled={disabled} onClick={(e) => reassignAll(e)} color="primary" variant="contained" size="small">
      Reassign all
    </Button>
  );
};

const UsersFilter = (props: any) => {
  const useStyles = usersStyles();
  const styles = useStyles();
  const { setFilters } = useListController();

  const roleChoices = [
    { id: 1, name: 'Meet Admin' },
    { id: 2, name: 'User' },
    { id: 3, name: 'Company Admin' },
  ];

  const clearFields = () => {
    setFilters({}, []);
  };

  return (
    <div className={styles.container}>
      <Stack direction='row'>
        <Filter {...props}>
          <TextInput source="email" label="Email" alwaysOn resettable sx={{ width: "200px" }} />
          <TextInput source="first_name" label="First name" alwaysOn resettable sx={{ width: "200px" }} />
          <TextInput source="last_name" label="Last name" alwaysOn resettable sx={{ width: "200px" }} />
          <SelectInput source="role" choices={roleChoices} emptyText="Any" label="Role" alwaysOn resettable sx={{ width: "200px" }} />
          <ReferenceArrayInput reference="teams" source="teams" alwaysOn resettable>
            <SelectArrayInput optionText="name" optionValue="id" label="Team" className={styles.teamsInput} sx={{ width: "200px" }} />
          </ReferenceArrayInput>
        </Filter>
        {props.context === 'button' ? (
          ''
        ) : (
          <Button variant="outlined" color="primary" onClick={clearFields}>
            Clear Filters
          </Button>
        )}
      </Stack>
    </div>
  );
};

const ListActions = () => {

  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);
  const [freeUserLimitReached, setFreeUserLimitReached] = useState(true);
  const { data, isLoading } = useGetList('users', { pagination: { page: 1, perPage: 1000 } },);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])

  useEffect(() => {
    data && setFreeUserLimitReached(Object.values(data).filter(e => e.membership_type === 1).length >= maxFreeUsersLimit)
  }, [data])

  return (
    <TopToolbar >
      <Stack direction="row" >
        {isLoading ? <CircularProgress /> :
          <CreateButton disabled={(isFreeAccout || freeUserLimitReached) && permissions?.role !== 1} />}
        {isFreeAccout && permissions?.role !== 1 ? <PremiumPlanPopup /> : null}
        <ExportButton />
      </Stack>
    </TopToolbar >
  )
}


export default function UsersList(props: any) {
  const [sent, setSent] = useState<number[]>([]);
  const notify = useNotify();

  const sendInvites = (event: MouseEvent, id: number) => {
    event.stopPropagation();
    event.preventDefault();

    http({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/users/send-invites/${id}/`,
    })
      .then((response) => {
        if (response.status === 401) {
          window.location.replace('/');
          return;
        }
        if (response.status < 200 || response.status > 299) {
          throw new Error('error message');
        }
        notify(`Invite has been sent`);
        setSent([...sent, id]);
        return;
      })
      .catch((error) => {
        notify('Invite could not be sent');
      });
  };

  const ButtonField = () => {
    const { id } = useRecordContext();
    const isDisabled = sent.includes(id as number);
    return (
      // @ts-ignore: Unreachable code error
      <Button variant="contained" color="primary" style={{ color: "white" }} onClick={(e) => sendInvites(e, id)} disabled={isDisabled}>
        Invite
      </Button>
    );
  };

  return (
    <List {...props} actions={<ListActions />} filters={<UsersFilter />} title="Hosts">
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <EmailField source="email" />
        <ReferenceArrayField reference="teams" source="teams">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField label="Membership Type" render={(record: any) => MembershipChoices[record.membership_type]} />
        <ReferenceField label="Invite" source="id" reference="users" sortable={false}>
          <ButtonField />
        </ReferenceField>
        <ReassignAllField />
        <TextField source="last_login_date" sortable={false} />
      </Datagrid>
    </List>
  );
}
