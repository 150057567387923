import { UserCalendar } from '../../types';
import { HttpResponse } from '../../types/http';
import authProvider from '../jwt/jwtAuthProvider';

interface IdentityData {
  user: {
    id: number;
    first_name: string;
    last_name: string;
    role: number;
    calendars: UserCalendar[];
    membership_type: number
  };
}

export const getTokenInfo = (): Promise<HttpResponse<IdentityData>> => {
  return authProvider.getIdentity();
};
