import { UrlField } from 'react-admin';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useRecordContext } from 'react-admin';

export default function Link(props: any) {
  const record = useRecordContext();

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UrlField
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...props}
      />
      <Tooltip title="Copy URL">
        <Button
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(record['url']).then(() => {
            }, (err) => {
              console.error('Text copy error: ', err);
            });
          }}
          style={{ padding: 0 }}
          size="small"
        >
          <ContentCopyIcon sx={{ height: "16px" }} />
        </Button>
      </Tooltip>
    </div>
  );
}
