import { List, Datagrid, TextField, ArrayField } from 'react-admin';
import Link from '../Link';
import { FromToField } from '../FromTo';

export default function CompaniesList(props: any) {
  return (
    <List {...props} title="Company details">
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="slug" sortable={false} />
        <TextField source="name" sortable={false} />
        <ArrayField source="open_time" sortable={false}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="weekday" />
            <FromToField source="from" />
            <FromToField source="to" />
          </Datagrid>
        </ArrayField>
        <Link source="webhook_url" sortable={false} />
      </Datagrid>
    </List>
  );
}
