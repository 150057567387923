
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import {
    CustomLogin,
    MeetingsList,
    MeetingCreate,
    EventTypesList,
    EventTypesCreate,
    EventTypesEdit,
    PrivateEventTypesCreate,
    PrivateEventTypesEdit,
    UsersList,
    UserEdit,
    UserCreate,
    TeamsList,
    TeamEdit,
    CompanyEdit,
    CompaniesList,
    TeamCreate,
    CompanyCreate,
    ActionList,
    ProfileEdit,
    ProfileList,
    DiscountCodesList,
    ChangelogList,
    ChangelogCreate,
    ChangelogEdit
} from './components';
// import drfProvider from './ra-djrf-provider';
import UserIcon from '@mui/icons-material/Group';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import BusinessIcon from '@mui/icons-material/Business';
import theme from './Theme';
import CustomLayout from './Layout/CustomLayout';
import i18nProvider from './i18n/i18nProvider';
import { Calendar } from './components/CalendarNew';
import '../node_modules/leaflet-geosearch/dist/geosearch.css';
import { subscriptions_url } from './compositions/http/endpoints';
import { editEventGroups } from './components/eventGroups/editEventGroups';
import { listEventGroups } from './components/eventGroups/listEventGroups';
import { CreateEventGroups } from './components/eventGroups/CreateEventGroups';
import { PaymentErrorInfo } from './components/stripePayments/PaymentErrorInfo';
import { SSOLogin } from './components/login/SSOLogin';
import { baseDrfDataProvider } from './compositions/jwt/baseDrfDataProvider';
import { httpClient } from './compositions/jwt/jwtHttpClient';
import authProvider from './compositions/jwt/jwtAuthProvider';
import { baseUrl } from './compositions/http/apis';


const dataProvider = baseDrfDataProvider(baseUrl, httpClient);

export const AppEntrypoint = () => {
    return (
        <Admin
            requireAuth
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            theme={theme}
            layout={CustomLayout}
            loginPage={CustomLogin}
        >
            <CustomRoutes>
                <Route path="/payment_err" element={<PaymentErrorInfo />} />
                <Route path="/calendar/overview" element={<Calendar />} />
                <Route path="/sso-login" element={<SSOLogin />} />
            </CustomRoutes>
            {(permissions) => {
                console.log("permissions")
                console.log(permissions)
                return [
                    <Resource
                        name="calendars/meetings"
                        options={{ label: 'Meetings' }}
                        list={MeetingsList}
                        create={MeetingCreate}
                        icon={EventAvailableIcon}
                    />,
                    permissions?.role !== 2 && (
                        <Resource
                            name={`calendars/event-types`}
                            options={{ label: 'Event types' }}
                            list={EventTypesList}
                            create={EventTypesCreate}
                            edit={EventTypesEdit}
                            icon={EventIcon}
                        />),
                    permissions?.role === 2 && (
                        <Resource
                            name={`calendars/event-types`}
                            options={{ label: 'Private Event types' }}
                            list={EventTypesList}
                            create={PrivateEventTypesCreate}
                            edit={PrivateEventTypesEdit}
                            icon={EventIcon}
                        />),
                    permissions?.role !== 2 && (
                        <Resource
                            name={`calendars/groups-event-types`}
                            options={{ label: 'Event groups' }}
                            list={listEventGroups}
                            create={CreateEventGroups}
                            edit={editEventGroups}
                        />),
                    permissions?.role !== 2 && (
                        <Resource name="users"
                            list={UsersList}
                            create={UserCreate}
                            edit={UserEdit}
                            icon={UserIcon}
                        />),
                    permissions?.role !== 2 && (
                        <Resource
                            name="teams"
                            list={TeamsList}
                            create={TeamCreate}
                            edit={TeamEdit}
                            icon={GroupWorkIcon}
                        />),
                    permissions?.role !== 2 && (
                        <Resource
                            name="companies"
                            list={CompaniesList}
                            create={permissions?.role === 1 ? CompanyCreate : undefined}
                            edit={CompanyEdit}
                            icon={BusinessIcon}
                        />),
                    permissions?.role !== 2 && (
                        <Resource
                            name={`calendars/discount-code`}
                            options={{ label: 'Discount codes' }}
                            list={DiscountCodesList}
                        />),
                    permissions?.role !== 2 && (
                        <Resource
                            name={`calendars/meeting-action-logs`}
                            options={{ label: 'Actions' }}
                            list={ActionList}
                            icon={EventIcon}
                        />),
                    permissions?.role === 1 && (
                        <Resource
                            name={`calendars/changelog`}
                            options={{ label: 'Changelog' }}
                            list={ChangelogList}
                            create={ChangelogCreate}
                            edit={ChangelogEdit}
                        />),
                    permissions?.role === 2 && (
                        <Resource
                            name="users"
                            options={{ label: 'Profile' }}
                            list={ProfileList}
                            edit={ProfileEdit}
                        />),
                    permissions?.role === 2 && <Resource name="calendars/private-event-details" />,
                    <Resource name={subscriptions_url} />
                ]
            }}
        </Admin>
    )
}