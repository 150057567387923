import { Edit, SimpleForm, required, TextInput, DateTimeInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export default function ChangelogEdit(props: any) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="version" />
        <DateTimeInput source="created_at" validate={[required()]}/>
        <DateTimeInput source="released_at"/>
        <RichTextInput source="content" />
      </SimpleForm>
    </Edit>
  );
}
