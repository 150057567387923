import { IconButton, Stack, type DialogProps } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useState } from 'react';
import { useRedirect, useStore } from 'react-admin';
import { getEvents, getTokenInfo, http } from '../../compositions/http';
import { CALENDAR_URL, EVENT_TYPE_CREATE, MEETINGS_URL } from '../../compositions/http/endpoints';
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import authProvider from "../../compositions/jwt/jwtAuthProvider";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const steps = [
    {
        label: 'Verify your account',
        description: `Integrate your smrt:meet account with your google calendar account by clicking the provided button. Be sure to check the following checkbox`,
    },
    {
        label: 'Create your first event',
        description:
            'Navigate to event types and create your your event (Don\'t worry you can delete it later)',
    },
    {
        label: 'Share the meeting',
        description: `Copy the link of the event type and share it with others`,
    },
    {
        label: 'See your meetings',
        description: `All of your meetings will be displayed in both the Meetings tab and the Calendar tab.`,
    }
];

export const OnboardingStepper = () => {
    const location = useLocation()
    const redirect = useRedirect()
    const [activeStep, setActiveStep] = useState(0)
    const [completedSteps, setCompletedSteps] = useStore('onboarding.tutorial.step', Array(steps.length).fill(false))
    const key = authProvider.getToken()

    const [modalOpen, setModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // const handleOpen = () => setModalOpen(true)

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return
        setModalOpen(false)
    }
    const handleNext = useCallback(() => {
        setCompletedSteps(prevStatus => {
            const newStatus = [...prevStatus];
            newStatus[activeStep] = true;
            return newStatus;
        });
    }, [activeStep, setCompletedSteps]);


    useEffect(() => {
        if (completedSteps[0] === true)
            return
        getTokenInfo()
            .then((data) => {
                http({
                    method: 'put', url: `/users/verify/?userId=${data.data.user.id}`,
                })
                    .then(response => response.data)
                    .then(data => {
                        if (data)
                            setCompletedSteps(prevStatus => { //if first step is not completed reset all steps
                                if (data) {
                                    const newStatus = [...prevStatus];
                                    newStatus[0] = data;
                                    return newStatus;
                                } else {
                                    return Array(prevStatus.length).fill(false);
                                }
                            });
                    })
                    .catch((error) => {
                        console.error("Error", error)
                    });
            });
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        if (completedSteps[1] === true)
            return
        getEvents(1, 1)
            .then(response => {
                if (response.data.count > 0)
                    setCompletedSteps(prevStatus => {
                        const newStatus = [...prevStatus]
                        newStatus[1] = true
                        return newStatus
                    });
            })
    }, [location, completedSteps, setCompletedSteps]);

    const checkInitialSteps = useCallback(async () => {
        try {
            const tokenInfo = await getTokenInfo();
            http({ method: "put", url: `/users/verify/?userId=${tokenInfo.data.user.id}` }).then(
                (verifyResponse) => {
                    console.log("verifyResponse")
                    if (verifyResponse.data) {
                        getEvents(1, 1).then(
                            (eventsResponse) => {
                                if (eventsResponse.data.count > 0) {
                                    setCompletedSteps(Array(steps.length).fill(true));
                                    setActiveStep(steps.length);
                                    setModalOpen(false);
                                    setIsLoading(false);
                                    return;
                                }
                            }
                        )
                    }
                    setIsLoading(false);
                }
            )
        } catch (error) {
            console.error("Error", error);
        }
    }, [setCompletedSteps]);

    useEffect(() => {
        checkInitialSteps();
        // eslint-disable-next-line
    }, []);

    useEffect(() => { //Update the stepper according to completedSteps
        if (completedSteps.every(step => step === true)) {
            setActiveStep(steps.length)
            return;
        }
        const firstActiveStep = completedSteps.findIndex(step => step === false)
        firstActiveStep === -1 ? setActiveStep(0) : setActiveStep(firstActiveStep)

    }, [completedSteps]);

    useEffect(() => {
        if (isLoading) return;
        //step 2 - came to create from modal, do not show modal
        if (completedSteps[0] === true && completedSteps[1] === false && location.pathname === `${EVENT_TYPE_CREATE}create`)
            return
        //step 3 - came to event types from modal, do not show modal and complete the step
        if (completedSteps[1] === true && completedSteps[2] === false && location.pathname === EVENT_TYPE_CREATE) {
            handleNext()
            return
        }
        //step 3 - came to event types or calendar from modal, do not show modal and complete the step
        if (completedSteps[2] === true && completedSteps[3] === false && (location.pathname === MEETINGS_URL || location.pathname === CALENDAR_URL)) {
            handleNext()
            setModalOpen(false)
            return
        }
        if (completedSteps.some(item => item === false))
            setModalOpen(true)
    }, [location, completedSteps, handleNext, isLoading])

    if (!modalOpen) return <></>

    return (
        <>
            {/* <Button onClick={handleOpen}>Open</Button> */}
            <Modal
                open={modalOpen}
                disableEnforceFocus
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={modalOpen}>
                    <Box sx={style}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="h4" component="h4">
                                Configure your account.
                            </Typography>
                            <IconButton onClick={() => setModalOpen(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === 3 ? (
                                                <Typography variant="caption">Last step</Typography>
                                            ) : null
                                        }
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            {index === 0 ? <img width={400} src={'meet-calendar-checkbox.png'} style={{ borderRadius: '20' }} alt="google calendar checkbox" /> : null}
                                            {index === 0 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} href={`${process.env.REACT_APP_BACKEND_URL}/users/add-google-calendar/?token=${key}`}>Verify Account</Button> : null}
                                            {/* {index === 0 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={() => {
                                                httpClient(`${process.env.REACT_APP_BACKEND_URL}/users/add-google-calendar/`).then(e => {
                                                    redirect(e.json.redirect)
                                                })
                                            }}>Verify Account</Button> : null} */}

                                            {index === 1 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={() => { redirect(`${EVENT_TYPE_CREATE}create`); setModalOpen(false) }}>Create an event type</Button> : null}
                                            {index === 2 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={() => { redirect(EVENT_TYPE_CREATE); setModalOpen(false) }}>Share the link</Button> : null}
                                            {index === 3 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={() => { redirect(MEETINGS_URL); }}>Check meetings</Button> : null}
                                            {index === 3 ? <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={() => { redirect(CALENDAR_URL); }}>Check calendar</Button> : null}
                                            {index !== 0 ? <Button variant="outlined" sx={{ mt: 1, mr: 1 }} onClick={() => handleNext()}>Continue</Button> : null}

                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re done</Typography>
                                <Button onClick={() => setModalOpen(false)} sx={{ mt: 1, mr: 1 }}>
                                    Complete
                                </Button>
                            </Paper>
                        )}

                    </Box>
                </Fade>
            </Modal>
        </>
    );
}