import { 
    ChipField,
    ReferenceArrayField, 
    SingleFieldList, 
    TextField,
    Datagrid, 
    List, 
} from "react-admin";

export const listEventGroups = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <ReferenceArrayField source="event_types" reference="calendars/event-types">
                <SingleFieldList >
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List >
);