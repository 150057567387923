import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { FormDataConsumer, usePermissions } from "react-admin";

import { getTokenInfo } from '../../compositions/http';
import UserCalendarField from './UserCalendarField';
import { UserCalendar } from '../../types';
import authProvider from '../../compositions/jwt/jwtAuthProvider';


export default function GoogleCalendarSettings(props: any) {

  const [calendars, setCalendars] = useState<UserCalendar[]>([]);
  const key = authProvider.getToken()
  console.log(key)

  useEffect(() => {
    getTokenInfo().then(({ data }: { data: { user: { calendars: UserCalendar[] } } }) => {
      setCalendars([...data.user.calendars]);
    });
  }, []);

  const { permissions } = usePermissions();
  const [isFreeAccout, setIsFreeAccout] = useState(false);

  useEffect(() => {
    setIsFreeAccout(permissions?.membership_type === 1) // 1 means free
  }, [permissions])

  return (
    <div>
      <Button
        href={`${process.env.REACT_APP_BACKEND_URL}/users/add-google-calendar/?token=${key}`}
        color="primary"
        variant="contained"
        size="small"
        disabled={isFreeAccout}
      >
        Add calendar
      </Button>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          calendars.map(
            (c) =>
              c.name !== formData.email && (
                <UserCalendarField
                  key={`calendar-${c.id}`}
                  calendars={{ calendars, setCalendars }}
                  id={c.id}
                  text={c.name}
                />
              ),
          )
        }
      </FormDataConsumer>
    </div>
  )
}

