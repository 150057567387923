import inMemoryJWT from './inMemoryJWT';
import { baseUrl } from "../http/apis"
import jwtDecode from "jwt-decode";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(baseUrl + '/token/', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                inMemoryJWT.setToken(data.access, data.delay)
            });
    },
    logout: () => {
        console.log("logging out")
        const request = new Request(baseUrl + '/token/invalidate/', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        inMemoryJWT.ereaseToken();

        return fetch(request).then(() => '/login');
    },
    checkAuth: () => {
        console.log('checkAuth');
        if (!inMemoryJWT.getToken()) {
            console.log("!inMemoryJWT.getToken()");
            return inMemoryJWT.getRefreshedToken().then(tokenHasBeenRefreshed => {
                return tokenHasBeenRefreshed ? Promise.resolve() : Promise.reject();
            });
        } else {
            console.log("inMemoryJWT.getToken()");
            return Promise.resolve();
        }
    },
    checkError: (error) => {
        console.log("checkError")
        const status = error.status;
        if (status === 401 || status === 403) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        console.log("getPermissions")
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            const data = jwtDecode(inMemoryJWT.getToken())
            return {
                id: data.user.id,
                role: data.user.role,
                membership_type: data.user.membership_type
            }
        })
    },
    // TODO: change me: extract from jwt
    getIdentity: () => {
        console.log("getIdentity")
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            const data = jwtDecode(inMemoryJWT.getToken())
            return Promise.resolve({
                data: {
                    user: {
                        fullName: data.user.first_name + " " + data.user.last_name,
                        avatar: '',
                        ...data.user
                    }
                }
            })
        });
    },
    isLoggedIn: () => {
        console.log("isLoggedIn")
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? true : false;
        });
    },
    getToken: () => {
        return inMemoryJWT.getToken();
    }
}

export default authProvider;