import { Grid } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from "react";
import { useGetOne, useRecordContext } from "react-admin";
import { subscriptions_url } from "../../compositions/http/endpoints";
import { getPublicKey, subscriptionInfo } from "../../compositions/http/subscriptions";
import { SubscribeButton } from "./SubscribeButton";
import UnsubscribeButton from "./UnsubscribeButton";

function format_time(s: any) {
  return new Date(s * 1e3).toISOString().slice(0, 10);
}

export const ShowSubscriptionInfo = (props: any) => {
  const record = useRecordContext();
  const [subInfo, setSubInfo] = useState<any>(null)

  useEffect(() => {
    subscriptionInfo(record.id).then(response => {
      setSubInfo(response.data)
    }).catch(error => {
    })
    // eslint-disable-next-line
  }, []);

  console.log(subInfo)

  return (
    <>
      {subInfo && subInfo.active ?
        <Grid container rowSpacing={2}>
          <Grid item xs={12}> Premium is <b>active</b></Grid>
          <Grid item xs={12}> Next payment on: <b>{format_time(subInfo.subscription.current_period_end)} </b></Grid>
          <Grid item xs={12}> <UnsubscribeButton id={record.id} dueDay={format_time(subInfo.subscription.current_period_end)} /> </Grid>
        </Grid>
        : null}
      {subInfo && !subInfo.active ?
        <Grid container rowSpacing={2}>
          <Grid item xs={12}> Premium is <b>active</b> due to <b>{format_time(subInfo.subscription.current_period_end)} </b></Grid>
          <Grid item xs={12}> Subscribe again to extend this period. </Grid>
          <Grid item xs={12}> <SubscribeButton id={record.id} /> </Grid>
        </Grid>
        : null}
    </>
  )
}

export const WithStripe = (props: any) => {
  const { children } = props;

  const [stripePromise, setStripePromise] = useState<any>(null);

  useEffect(() => {
    getPublicKey().then(response => {
      setStripePromise(loadStripe(response.data.publicKey));
    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {stripePromise === null ? null :
        <Elements stripe={stripePromise}>
          {children}
        </Elements>
      }
    </div>
  )
}

export const AppBarSubscribeButton = (props: any) => {
  const { id } = props;

  return (
    <WithStripe>
      <SubscribeButton id={id} />
    </WithStripe>
  )
}

export const SubscriptionInfoManager = () => {
  const { id } = useRecordContext();
  const { data, error } = useGetOne(subscriptions_url, {id});

  return (
    <WithStripe>
      {data && !error ?
        <ShowSubscriptionInfo /> :
        <SubscribeButton id={id} />}
    </WithStripe>
  )
}
