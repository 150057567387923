import { HttpResponse } from '../../types';
import { IChangelogResult } from '../../types/changelog';
import { CHANGELOG_URL } from './endpoints';
import http from './http';

export const changelogGet = (): Promise<HttpResponse<IChangelogResult>> =>
  http({
    url: `${CHANGELOG_URL}`,
    handleError: true,
    putToken: true,
  });
