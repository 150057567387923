import { Button, CircularProgress, Stack } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useRefresh } from "react-admin";
import { createCheckoutSession } from "../../compositions/http/subscriptions";
import { PremiumPlanPopup } from "./PlansDescriptions";

export const SubscribeButton = (props: any) => {
    const { id } = props;

    const stripe = useStripe();
    const refresh = useRefresh();
    const [showWait, setShowWait] = useState(false);

    const subscribe = () => {
        setShowWait(true);

        createCheckoutSession(id).then((result) => {
            const data = result.data
            if (data !== null) {
                stripe?.redirectToCheckout({ sessionId: data.sessionId })
            } else {
                refresh()
            }
        }).finally(() => {
            refresh()
        }
        )
    }

    return (
        <>
            {showWait ? <CircularProgress /> :
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Button
                        onClick={subscribe}
                        size="small"
                        variant="contained" >
                        Go Premium
                    </Button >
                    <PremiumPlanPopup />
                </Stack>
            }
        </>
    );
}
