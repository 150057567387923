import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  SelectInput,
  PasswordInput,
  Toolbar,
  SaveButton,
  usePermissions,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { getTokenInfo } from '../../compositions/http';
import { UserCalendar } from '../../types';
import GoogleCalendarSettings from '../GoogleCalendar/GoogleCalendarSettings';
import { SubscriptionInfoManager } from '../stripePayments/SubscriptionInfoManager';

const fieldStyles = () => {
  return makeStyles({
    field: {
      width: '256px',
    },
    fieldDisabled: {
      display: 'none !important',
    },
    form: {
      '& > *': {
        display: 'block',
        '& > *': {
          width: '256px',
        },
      },
    },
    iterator: {
      display: 'block',
      listStyleType: 'disc',
      marginBlockEnd: '1em',
      marginInlineStart: '0px',
      marginInlineEnd: '0px',
      '& > li': {
        padding: 0,
        '& > p': {
          display: 'none',
        },
      },
    },
  });
};
const ProfileEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
export default function ProfileEdit(props: any) {
  const useStyles = fieldStyles();
  const styles = useStyles();
  const [calendars, setCalendars] = useState<UserCalendar[]>([]);
  const { permissions } = usePermissions();

  useEffect(() => {
    getTokenInfo().then(({ data }: { data: { user: { calendars: UserCalendar[] } } }) => {
      setCalendars([...data.user.calendars]);
    });
  }, []);

  // useEffect(() => {
  //   dataProvider.getOne('companies', { id }).then(({ data }) => {
  //     const { name, payment_data } = data;
  //     setTitle(name);
  //     setPaymentDataId(payment_data);
  //   });
  // }, [dataProvider, id]);


  return (
    <Edit {...props} title="Profile">
      <SimpleForm toolbar={<ProfileEditToolbar />} defaultValues={{ ...props.record, calendars }}>
        <TextInput disabled source="id" />
        <TextInput disabled source="last_login_date" />
        <TextInput source="first_name" validate={[required()]} />
        <TextInput source="last_name" validate={[required()]} />
        <TextInput type="email" source="email" disabled validate={[email(), required()]} />
        <SelectInput
          disabled
          source="role"
          choices={[
            { id: 1, name: 'Super Admin' },
            { id: 2, name: 'User' },
            { id: 3, name: 'Company Admin' },
          ]}
        />
        <PasswordInput source="password" disabled className={styles.fieldDisabled} />
        <PasswordInput source="password" className={styles.field} />
        <GoogleCalendarSettings />
        {
          permissions?.membership_type === 1 ?
            <SubscriptionInfoManager/> : null
        }
      </SimpleForm>
    </Edit>
  );
}
