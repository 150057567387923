import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceArrayField,
  SingleFieldList,
  useListController,
} from 'react-admin';
import Link from '../Link';
import FirstLastNameField from './FirstLastNameField';
import Button from '@mui/material/Button';
import { containerStyles } from '../../styles';

const TeamsFilter = (props: any) => {
  const styles = containerStyles();
  const { setFilters } = useListController();

  const clearFields = () => {
    setFilters({}, []);
  };

  return (
    <div className={styles.container}>
      <Filter {...props}>
        <TextInput source="name" label="Name" alwaysOn resettable />
        <TextInput source="slug" label="Slug" alwaysOn resettable />
      </Filter>
      {props.context === 'button' ? (
        ''
      ) : (
        <Button variant="outlined" color="primary" onClick={clearFields}>
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default function TeamsList(props: any) {
  return (
    <List {...props} filters={<TeamsFilter />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="slug" />
        <ReferenceField label="Company" source="company" reference="companies">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField label="Members" source="users" reference="users">
          <SingleFieldList>
            <FirstLastNameField />
          </SingleFieldList>
        </ReferenceArrayField>
        <Link label="Events" source="url" sortable={false} />
      </Datagrid>
    </List>
  );
}
