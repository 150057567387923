import React from 'react';
import { Sidebar, MenuItemLink, usePermissions } from 'react-admin';
import { makeStyles, useTheme } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import GroupIcon from '@mui/icons-material/Group';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import BusinessIcon from '@mui/icons-material/Business';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import TimelineIcon from '@mui/icons-material/Timeline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FaceIcon from '@mui/icons-material/Face';
import DiscountIcon from '@mui/icons-material/Discount';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CustomFooter from './CustomFooter'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const sidebarStyles = (theme: Theme) => {
  return makeStyles({
    sidebar: {
      backgroundColor: theme.palette.primary.main,
      marginRight: '20px',
    },
    text: {
      color: 'white',
    },
    container: {
      marginTop: 30,
    },
    active: {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    hover: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    footer: {
      position: 'absolute',
      bottom: '20px',
    }
  });
};

const CustomAppBar = (props: any) => {
  const theme = useTheme();
  const useStyles = sidebarStyles(theme);
  const styles = useStyles();

  const currentRoute = window.location.hash.substr(1);
  const { permissions } = usePermissions();

  const routes = [
    {
      to: '/calendars/meetings',
      value: 'Meetings',
      icon: (
        <EventAvailableIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/meetings') && styles.activeIcon}`}
        />
      ),
      roles: [1, 2, 3],
    },
    {
      to: '/calendars/event-types',
      value: 'Event Types',
      icon: (
        <EventIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/event-types') && styles.activeIcon}`}
        />
      ),
      roles: [1, 2, 3],
    },
    {
      to: '/calendars/groups-event-types',
      value: 'Event Types Groups',
      icon: (
        <EventIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/groups-event-types') && styles.activeIcon}`}
        />
      ),
      roles: [1, 3],
    },
    {
      to: '/users',
      value: 'Hosts',
      icon: <GroupIcon htmlColor="white" className={`${currentRoute.startsWith('/users') && styles.activeIcon}`} />,
      roles: [1, 3],
    },
    {
      to: '/teams',
      value: 'Teams',
      icon: <GroupWorkIcon htmlColor="white" className={`${currentRoute.startsWith('/teams') && styles.activeIcon}`} />,
      roles: [1, 3],
    },
    {
      to: '/companies',
      value: 'Company details',
      icon: (
        <BusinessIcon htmlColor="white" className={`${currentRoute.startsWith('/companies') && styles.activeIcon}`} />
      ),
      roles: [1, 3],
    },
    {
      to: '/calendars/discount-code',
      value: 'Discount codes',
      icon: (
        <DiscountIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/discount-code') && styles.activeIcon}`}
        />
      ),
      roles: [1, 3],
    },
    {
      to: '/calendars/meeting-action-logs',
      value: 'Actions',
      icon: (
        <TimelineIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/meeting-action-logs') && styles.activeIcon}`}
        />
      ),
      roles: [1, 3],
    },
    {
      to: '/calendar/overview',
      value: 'Calendar',
      icon: (
        <DateRangeIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendar/overview') && styles.activeIcon}`}
        />
      ),
      roles: [1, 2, 3],
    },
    {
      to: '/calendars/changelog',
      value: 'Changelog',
      icon: (
        <PublishedWithChangesIcon
          htmlColor="white"
          className={`${currentRoute.startsWith('/calendars/changelog') && styles.activeIcon}`}
        />
      ),
      roles: [1],
    },
    {
      to: '/users',
      value: 'Profile',
      icon: <FaceIcon htmlColor="white" className={`${currentRoute.startsWith('/users') && styles.activeIcon}`} />,
      roles: [2],
    },
  ];

  return (
    <Sidebar {...props} color="primary" className={styles.sidebar}>
      <div className={styles.container}>
        {routes.map((route) => (
          <>
            {route.roles.includes(permissions?.role) && (
              <MenuItemLink
                to={route.to}
                primaryText={route.value}
                leftIcon={route.icon}
                className={`${styles.text} ${currentRoute.startsWith(route.to) ? styles.active : styles.hover}`}
                key={route.value}
                sidebarIsOpen={currentRoute.startsWith(route.to)}
              />
            )}
          </>
        ))}
      </div>
      <div className={styles.footer}><CustomFooter/></div>
    </Sidebar>
  );
};

export default CustomAppBar;
